import React, { useEffect, useState } from "react";
import {
  GroupSavingsCoopStage,
  SavingDetails,
} from "components/templates/NewSavingTemplate";
import { StageMapperValue } from "./SavingsTypes";
import { NewSavingsContainer } from "./NewSavingsContainer";
import { toast } from "react-toastify";
import { createGroupTarget } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "redux/store";

function NewTargetSavings() {
  const dispatch = useDispatch();

  const formStagesMapper: Record<GroupSavingsCoopStage, StageMapperValue> = {
    name: [null, "addMembers"],
    addMembers: ["name", "confirmModal"],
    confirmModal: ["name", "confirm"],
    confirm: ["confirmModal", null],
  };
  const [createSuccessfully, setcreateSuccessfully] = useState(false);
  const createSavingState = useSelector(
    (state: RootReducer) => state.saving.createGroupTarget
  );

  useEffect(() => {
    if (createSavingState.success) {
      setcreateSuccessfully(true);
      toast.success("Plan has been created");
    }
  }, [createSavingState.success]);

  //handles errors creating the saving plan
  useEffect(() => {
    if (
      Object.keys(createSavingState.errors).length !== 0 &&
      createSavingState.errors.constructor !== Object
    ) {
      toast.error(createSavingState.errors);
    }
  }, [createSavingState.errors]);

  const handleNextPageIsEmpty = (values) => {
    // prepare values for backend
    // return alert('Submitting');
    const members: any = localStorage.getItem("members");
    const payload = JSON.parse(members);
    const sendToDB = {
      name: values.name,
      target_amount: values.target,
      start_date: values.startDate?.format("YYYY-MM-DD"),
      end_date: values.endDate?.format("YYYY-MM-DD"),
      no_of_participants: Number(values.members),
      description: "Nothing here",
      callback_url: window.location.origin,
      data: payload,
    };

    dispatch(createGroupTarget(sendToDB));
    toast.info("Processing please wait...");
  };

  const newTargetSavings: SavingDetails = {
    savingType: "Target",
    description: "Give your Target group saving plan a name",
    savingPlaceholder: "Church bus",
    target: "Set a target amount",
    frequency: "Pay-in Frequency",
  };

  return (
    <NewSavingsContainer
      savingDetails={newTargetSavings}
      formStagesMapper={formStagesMapper}
      onNextPageIsEmpty={handleNextPageIsEmpty}
      isCreateSuccessful={createSuccessfully}
      Id={createSavingState.data.id}
    />
  );
}

export default NewTargetSavings;
