import React from "react";
import styled from "@emotion/styled";
import Button from "components/atoms/CustomButton";
import checkIcon from "assets/icons/SuccessMark.svg";
import { useHistory } from "react-router-dom";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import { LOGIN } from "../pagesPath";
import AuthHeading from "components/typographpy/AuthHeading";

const PasswordResetSuccess = () => {
  const history = useHistory();

  return (
    <PasswordResetSuccess.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="icon">
            <img src={checkIcon} alt="icon" className="check-icon" />
          </div>
          <div className="title">
            <AuthHeading
              primaryHeading={"Password reset"}
              secondaryHeading={"successful"}
            />
          </div>
          <div className="button">
            <Button
              onClick={() => history.push(LOGIN)}
            >
              Login
              </Button>
          </div>
        </div>
      </AuthCard>
    </PasswordResetSuccess.Wrapper>
  );
};

PasswordResetSuccess.Wrapper = styled.div`
  .icon {
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    .check-icon {
      width: 20rem;
      object-fit: cover;
    }
  }

  .title {
    padding: 2rem 0;
    text-align: center;
  }
  .button {
    padding: 1rem 0;
    text-align: center;
  }
`;
export default PasswordResetSuccess;
