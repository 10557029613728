import React from "react";
import styled from "@emotion/styled";
import blueCoin from "assets/images/blueCoin.svg";
import transparentCoin from "assets/images/transparentCoin.svg";

const AuthBackground = () => {
  return (
    <AuthBackground.Wrapper>
      <img src={blueCoin} className="blue" alt="blueCoin" />
      <img
        src={transparentCoin}
        className="transparent"
        alt="transparentCoin"
      />
    </AuthBackground.Wrapper>
  );
};

AuthBackground.Wrapper = styled.div`
  position: absolute;
  background-color: #e6ffea;
  top: 0;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 52%, 0 41%);
  z-index: -1;
  min-height: 100vh;
  .blue {
    position: absolute;
    right: 0;
  }
  .transparent {
    position: absolute;
    left: 0;
    width: 300px;
    height: 300px;
  }
`;
export default AuthBackground;
