import React, { useState } from "react";
import styled from "@emotion/styled";
import AuthCard from "components/cards/AuthCard";
import AuthBackground from "components/AuthBackground";
import Button from "components/atoms/CustomButton";
import AuthBrand from "components/AuthBrand";
import Input from "components/Input";
import { Link } from "react-router-dom";
import { LOGIN, VERIFY_USER_TOKEN } from "../pagesPath";
import { useDispatch } from 'react-redux';
import { resendVerificationRequest } from 'redux/actions';
import { toast } from 'react-toastify';

const ResendVerification = () => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(resendVerificationRequest({
      email,
      callback_url: `${window.location.origin}${VERIFY_USER_TOKEN}`,
    }));
    toast.info("Processing please wait")
  }


  return (
    <ResendVerification.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="title">
            <div className="primary-heading">
              Did not
              <span className="secondary-heading"> get verification</span>
            </div>

            <div className="sm-heading">
              Please enter your email address to request for another
              verification link
            </div>
          </div>
          <Input name="email" type="email" placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="link">
            <Link to={LOGIN} className="link">
              Back to Signin
            </Link>
          </div>

          <div className="button"
            onClick={handleSubmit}
          >
            <Button height="4rem">Send</Button>
          </div>
        </div>
      </AuthCard>
    </ResendVerification.Wrapper>
  );
};

ResendVerification.Wrapper = styled.div`
  .title {
    margin: 2.5rem auto;
    text-align: center;
    .primary-heading {
      font-size: 2rem;
      font-weight: bold;
      color: #059157;
    }
    .secondary-heading {
      color: #47486b;
      font-style: italic;
    }
    img {
      height: 2.5rem;
    }
  }
  .sm-heading {
    margin: 1.5rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #080b4d;
    opacity: 0.6;
  }
  .link {
    color: #08cb7b;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-decoration: none;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 960px) {
    margin: 14rem 0;
  }
`;

export default ResendVerification;
