import { ConfirmPlanModalProps } from "./sharedType";
import { Modal } from "../Modal";
import React from "react";
import { ConfirmIndividualPlanCard } from "./card";

export function ConfirmPlanModal(props: ConfirmPlanModalProps) {
  return (
    <Modal>
      <ConfirmIndividualPlanCard {...props} cardWidth="41%" />
    </Modal>
  );
}
