/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ConfirmCardProps } from "./sharedType";
import { Heading } from "../typographpy/Heading";
import React from "react";
import BackButton from "../BackButton";
import CustomButton from "../atoms/CustomButton";
import styled from "@emotion/styled";
import { DARK_PURPLE, DARK_GREEN } from "styles/__color";
import { css } from "@emotion/core";

export function ConfirmIndividualPlanCard(props: ConfirmCardProps) {
  const {
    values,
    onBackClick,
    onConfirmClick,
    inlineConfirmation = false,
    cardWidth,
    hideBtnSection = false,
  } = props;

  //const amnt: number = Number(values.members) * values.target;

  const calcDuration = (start, endDate) => {
    // eslint-disable-next-line no-console
    console.log(endDate - start);
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <ConfirmIndividualPlanCard.Wrapper
      inlineConfirmation={inlineConfirmation}
      cardWidth={cardWidth}
    >
      <div className="header flex m-10">
        <Heading type="h2" family="Muli-ExtraBold" color={DARK_PURPLE}>
          {values.name} {props.type === "Group" ? "Co-operative" : "Target"}
        </Heading>

        {/*{inlineConfirmation ? (*/}
        {/*  <i className="fas fa-user fa-2x"></i>*/}
        {/*) : (*/}
        {/*  <i className="fas fa-pencil-alt fa-2x"></i>*/}
        {/*)}*/}
      </div>
      <div className="daily-value mt-3">
        <Heading type={"h2"}>
          {" "}
          {props.type === "Target" ? "Amount/Person" : "Amount/Cycle"}
        </Heading>
        {/*{values.frequency?.toUpperCase()}</h5>*/}
        <h1>
          ₦
          {
            // eslint-disable-next-line no-console
            console.log("Prop type", props.type)
          }
          {props.type === "Target"
            ? values.target + "/person"
            : values.routineAmount + "/" + values.frequency}
        </h1>
      </div>
      <div className="date-target flex mt-3">
        <div>
          <Heading family="Muli" color="#33277B" type="h5" opacity="0.5">
            Total invites to be sent
          </Heading>
          <Heading family="Muli-ExtraBold" type="h5" color={DARK_GREEN}>
            {values.members}
          </Heading>
        </div>
        <div className="start-date">
          <Heading family="Muli" color="#33277B" type="h5" opacity="0.5">
            Commission
          </Heading>
          <Heading family="Muli-ExtraBold" type="h5" color={DARK_GREEN}>
            2%
          </Heading>
        </div>
        <div className={"duration"}>
          <Heading type={"h5"} color={"#332778"} family={"Muli"}>
            Duration
          </Heading>
          <Heading family="Muli-ExtraBold" type="h5" color={DARK_GREEN}>
            <></>{" "}
            {
              // @ts-ignore
              calcDuration(values.startDate, values.endDate)
            }
          </Heading>
        </div>
      </div>
      {props.type !== "Target" && (
        <div className="mt-3">
          <Heading family="Muli" type="h5" color="#33277B80">
            Duration
          </Heading>
          <Heading family="Muli-ExtraBold" type="h5" color={DARK_GREEN}>
            {values.members}{" "}
            {values.frequency === "monthly"
              ? "Month(s)"
              : values.frequency === "weekly"
              ? "Week(s)"
              : "Day(s)"}
          </Heading>
        </div>
      )}

      {!hideBtnSection && (
        <div className="button-group flex mt-3">
          <span className="back-wrapper">
            <BackButton color="#33277B" hideLt onClick={onBackClick} />
          </span>
          <CustomButton onClick={onConfirmClick} backgroundColor="#33277B">
            Confirm Plan
          </CustomButton>
        </div>
      )}
    </ConfirmIndividualPlanCard.Wrapper>
  );
}

ConfirmIndividualPlanCard.Wrapper = styled.div<{
  cardWidth: string;
  inlineConfirmation: boolean;
}>`
  /* width: 41.053%; */
  width: ${(props) => props.cardWidth};
  background-color: #f8fffe;
  border-radius: 20px;
  padding: 2rem 3rem;
  border: 1px solid #bec6df;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mt-3 {
    margin-bottom: 3rem;
  }
  .m-10 {
    margin: 10px;
  }
  .header {
    i {
      background-color: #54cd97;
      padding: 2.1rem;
      border-radius: 50%;
      color: #fff;
    }
  }
  .daily-value {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e9e9e9;
    h5 {
      font-family: Muli-Bold;
      color: #51459e;
      font-size: 1.4rem;
      opacity: 0.7;
      margin-bottom: 1.5rem;
    }
    h1 {
      font-family: Muli-Bold;
      color: ${DARK_PURPLE};
      font-size: 2.5rem;
    }
  }

  .button-group {
    justify-content: flex-end;
    button {
      margin-left: 2rem;
    }
  }

  ${(props) =>
    !props.inlineConfirmation &&
    css`
      box-shadow: 0px 70px 90px #00635c0d;
    `}
  @media screen and (max-width: 960px) {
    width: 90%;
  }
  .date-target h5 {
    margin-bottom: 1.5rem;
  }
`;

/*
No of days btw two dates
var start = moment("2018-03-10", "YYYY-MM-DD");
var end = moment("2018-03-15", "YYYY-MM-DD");

//Difference in number of days
moment.duration(start.diff(end)).asDays();

//Difference in number of weeks
moment.duration(start.diff(end)).asWeeks();

const monthDifference =  moment(new Date(endDate)).diff(new Date(startDate), 'months', true);
*/
