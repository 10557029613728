import { css } from "@emotion/core";
import MuliRegular from "assets/fonts/Muli/Muli-Regular.ttf";
import MuliBold from "assets/fonts/Muli/Muli-Bold.ttf";
import MuliSemiBold from "assets/fonts/Muli/Muli-SemiBold.ttf";
import MuliExtraBold from "assets/fonts/Muli/Muli-ExtraBold.ttf";
import MuliLight from "assets/fonts/Muli/Muli-Light.ttf";
import MuliBlack from "assets/fonts/Muli/Muli-Black.ttf";
import Graphie from "assets/fonts/Graphie/Graphie-Regular.otf";
import GraphieBold from "assets/fonts/Graphie/Graphie-Bold.otf";
import GraphieExtraBold from "assets/fonts/Graphie/Graphie-ExtraBold.otf";
import { BACKGROUND } from "./__color";

export default css`
  @font-face {
    font-family: Muli;
    src: url(${MuliRegular}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Muli-Bold;
    src: url(${MuliBold}) format("truetype");
    font-weight: bold;
    font-style: bold;
  }
  @font-face {
    font-family: Muli-Light;
    src: url(${MuliLight}) format("truetype");
  }
  @font-face {
    font-family: Muli-SemiBold;
    src: url(${MuliSemiBold}) format("truetype");
  }
  @font-face {
    font-family: Muli-ExtraBold;
    src: url(${MuliExtraBold}) format("truetype");
  }
  @font-face {
    font-family: Muli-Black;
    src: url(${MuliBlack}) format("truetype");
  }
  @font-face {
    font-family: Graphie;
    src: url(${Graphie}) format("opentype");
  }
  @font-face {
    font-family: Graphie-Bold;
    src: url(${GraphieBold}) format("opentype");
  }
  @font-face {
    font-family: Graphie-ExtraBold;
    src: url(${GraphieExtraBold}) format("opentype");
  }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  html {
   // This defines what 1rem is
   font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  }
  
  body {
    font-family: Muli, "Fira Sans", sans-serif;
    box-sizing: border-box;
    background-color: ${BACKGROUND};
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

iframe{
  height: 100vh;
  width: 78vw;
}
#plugin{
  height: 100vh;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  @media screen and (max-width: 960px) {
    body{
      background-color: white;
    }
  }
  .Toastify__toast-container {
    font-size: 1.6rem;
  }
  /* this is temporary to test the style for date picker */
  .DateInput,
  .DateInput_1{
    width:100% !important;
  }
  .react-datepicker__month-container {
    background-color: #fff;
    color: black;
    font-size: 1.6rem;

    .react-datepicker__month {
      padding: 1rem 0;
    }

    .react-datepicker__month-text {
      display: inline-block;
      width: 5rem;
      margin: 0.5rem;
      font-size: 1rem;
      padding: 0.2rem;
      &:hover {
        background-color: #534cea;
      }
    }
  }
`;
