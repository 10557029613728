import React from "react";
import styled from "@emotion/styled";

interface InputProps {
  className?: string;
  onChange?: any;
  name: string;
  value?: any;
  type: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  cols?: number;
  rows?: number;
  readOnly?:boolean;
  pattern?: string;
  onClick?: any;
  disabled?: boolean;
  autoComplete?: any;
}

const Input = (props: InputProps) => {
  const {
    onChange,
    label,
    value,
    type,
    name,
    pattern,
    placeholder,
    cols = 10,
    rows = 5,
    onClick,
      readOnly,
    required,
    disabled,
    autoComplete
  } = props;

  return (
    <Input.Wrapper className="form">
      <div className="form">
        {type === "textarea" ? (
          <textarea
            className="form__input"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            cols={cols}
            rows={rows}
            disabled={disabled}
          ></textarea>
        ) : (

            <input
              className={`${value?.length ? "filled" : ""} form__input`}
              type={type}
              placeholder={placeholder}
              name={name}
              value={value}
              pattern={pattern}
              readOnly={readOnly}
              onChange={onChange}
              required={required}
              onClick={onClick}
              disabled={disabled}
              autoComplete={autoComplete}
            />
          )}
        <label
          htmlFor={name}
          className={`${value?.length ? "shrink" : ""} form__label`}
        >
          {label}
        </label>
      </div>
    </Input.Wrapper>
  );
};

Input.Wrapper = styled.div`
  margin-top: 2rem;
  .filled{
    background-color: #E6FFEA;
    border: 0.5px solid #059157;
    }   
  .form {
    position: relative;
    &__label {
      position: absolute;
      top: 0;
      left: 0.5rem;
      padding: 0 1rem;
      font-size: 1.2rem;
      line-height: 1.5rem;
      background-color: #E6FFEA;
      display: block;
      transition: all 0.3s;
      opacity: 0;
      color:#059157;
    }
    &__input {
      font-size: 1.6rem;
      font-family: Muli-Bold;
      color: #059157;
      padding: 1rem;
      padding-left: 2rem;
      border-radius: 1rem;
      border: none;
      width: 100%;
      display: block;
      transition: all 0.3s;
      box-shadow: 0px 3px 6px #0000000d;
      height: 5.5rem;
      &:focus {
        outline: none;
        border: 0.5px solid #bec6df;
        background-color:none;
      }

      &:focus:invalid {
        border: 1px solid red;
      }

      &::-webkit-input-placeholder {
        font-family: Muli;
        color: #47486b;
        opacity: 0.4;
      }
      &:disabled {
        background-color: #dadceb;
        color: #47486B;
        cursor: not-allowed;
      }
    }
    .shrink {
      top: -0.8rem;
      opacity: 1;
    } 
  }
  
`;
export default Input;
