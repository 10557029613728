import * as types from "redux/types";

export const signRequest = (payload: any) => ({
  type: types.SIGN_IN_REQUEST,
  payload,
});

export const signUpRequest = (payload: any) => ({
  type: types.SIGN_UP_REQUEST,
  payload,
});

export const userDetailsRequest = () => ({
  type: types.GET_USER_DETAILS_REQUEST,
});
export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});

export const uploadImageRequest = (payload) => ({
  type: types.UPLOAD_IMAGE_REQUEST,
  payload
});
export const updateProfileRequest = (payload) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload
});
export const forgotPasswordRequest = (payload) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload
});
export const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD.request,
  payload
});

export const verifyUserTokenRequest = (payload) => ({
  type: types.VERIFY_USER_TOKEN_REQUEST,
  payload
})
export const resendVerificationRequest = (payload) => ({
  type: types.RESEND_VERIFICATION.request,
  payload
})

export const updatePasswordRequest = (payload) => ({
  type : types.UPDATE_PASSWORD.request,
  payload
})

