import React from "react";
import styled from "@emotion/styled";

interface AuthHeadingProps {
  icon?: string;
  secondaryHeading: string;
  primaryHeading: string;
}

const AuthHeading = (props: AuthHeadingProps) => {
  const { icon, secondaryHeading, primaryHeading } = props;

  return (
    <AuthHeading.Wrapper>
      {icon && <img src={icon} alt="hand" />} {primaryHeading}{" "}
      <p className="secondary-heading">{secondaryHeading}</p>
    </AuthHeading.Wrapper>
  );
};

AuthHeading.Wrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #059157;
  display: flex;
  justify-content: center;
  img {
    height: 2.5rem;
  }

  .secondary-heading {
    font-weight: 200;
    color: #47486b;
    font-style: italic;
    margin-left: 1rem;
  }
`;

export default AuthHeading;
