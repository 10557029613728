import React, { useEffect, useState } from "react";
import { convertRoutesToComponents } from "helpers/pagesParser";
import { protectedRoutes } from "./routes";
import SideBar from "components/SideBar";
import styled from "@emotion/styled";
import {
  HOME,
  SAVINGS,
  TRANSACTION,
  ACCOUNT,
  LOGIN,
  WITHDRAWAL,
} from "../pagesPath";
import { ReactComponent as HomeIcon } from "assets/images/sidebar/dashboard.svg";
import { ReactComponent as Account } from "assets/images/sidebar/account.svg";
import { ReactComponent as Transaction } from "assets/images/sidebar/transaction.svg";
import { ReactComponent as Savings } from "assets/images/sidebar/savings.svg";
// import { ReactComponent as Bank } from "assets/images/sidebar/Bank.svg";
import {
  getAllInvites,
  getAllTarget,
  getCardsRequest,
  userDetailsRequest,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootReducer } from "redux/store";
import LoadingSkeletonBody from "components/atoms/LoadingSkeletonBody";
// import Unauthorized from "pages/Unauthorized";
import hamburgerIcon from "assets/images/landingPage/icon-hamburger.svg";
import AutoLogOut from "components/templates/AutoLogOut";
import Login from "pages/UnAuthPages/Login";

const AuthPages = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push(LOGIN);
    } else {
      dispatch(userDetailsRequest());
      dispatch(getCardsRequest());
      dispatch(getAllInvites());
      dispatch(getAllTarget());
    }
  }, [dispatch, history]);
  const { data, processing } = useSelector(
    (state: RootReducer) => state.auth.user
  );
  const groupInvites = useSelector(
    (state: RootReducer) => state.saving.groupInvites
  );
  const targetInvites = useSelector(
    (state: RootReducer) => state.saving.targetInvites
  );
  const sidebarRoutes = [
    {
      path: HOME,
      icon: HomeIcon,
      label: "Dashboard",
    },
    {
      path: SAVINGS,
      icon: Savings,
      label: "Savings",
    },
    {
      path: TRANSACTION,
      icon: Transaction,
      label: "Transactions",
    },
    {
      path: ACCOUNT,
      icon: Account,
      label: "Account",
    },
    {
      path: WITHDRAWAL,
      icon: Savings,
      label: "Withdrawal",
    },
  ];

  if (processing || groupInvites.processing) {
    return <LoadingSkeletonBody />;
  }

  if (processing || targetInvites.processing) {
    return <LoadingSkeletonBody />;
  }

  if (Object.keys(data).length === 0 && data.constructor === Object) {
    return <Login />;
  }

  const handleClick = () => {
    setShow(!show);
  };

  const onTimeOut = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  if (data) {
    return (
      <div>
        <SideBarWrapper>
          <div className={`${!show ? "left" : ""}`}>
            <SideBar pages={sidebarRoutes} hideSideBar={handleClick} />
            {/*<SideBar pages={[{label: "Logout", path:"", icon:"",}]} hideSideBar={logoutRequest} />*/}
          </div>
        </SideBarWrapper>
        <HamburgerWrapper>
          <img src={hamburgerIcon} alt="" onClick={handleClick} />
        </HamburgerWrapper>

        <PagesWrapper>
          <main className="pages-parent">
            {convertRoutesToComponents(protectedRoutes)}
          </main>
        </PagesWrapper>
        <AutoLogOut timeout={1000 * 60 * 5} onTimeOut={onTimeOut} />
      </div>
    );
  }

  return <div>Nothing here</div>;
};

const PagesWrapper = styled.div`
  position: relative;
  margin-left: 20%;
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin: 2rem;
  }
`;
const SideBarWrapper = styled.div`
  .left {
    position: relative;
    left: auto;
  }

  @media screen and (max-width: 960px) {
    .left {
      left: -100%;
    }
  }
`;
const HamburgerWrapper = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    padding: 3rem 3rem 0 3rem;
    text-align: end;
    cursor: pointer;
  }
`;

export default AuthPages;
