import { takeLatest, call } from "redux-saga/effects";
import api, { savingRequest } from "services/api";
import * as types from "redux/types";
import { safeSaga } from "helpers";
import { successHandler } from "helpers/apiRequests";
import {
  GET_SINGLE_ACCEPTED_GROUP_REQUEST,
  GET_SINGLE_ACCEPTED_GROUP_ERROR,
  GET_SINGLE_ACCEPTED_GROUP_SUCCESS,
  GET_PAYOUT_STATUS_SUCCESS,
  GET_PAYOUT_STATUS_REQUEST,
  GET_PAYOUT_STATUS_ERROR,
  GET_SINGLE_ACCEPTED_TARGET_SUCCESS,
} from "redux/types";

function* getIndividualSavings() {
  const response = yield call([api, "get"], savingRequest.INDIVIDUAL_SAVING);

  yield call(successHandler, response, types.GET_SAVINGS_SUCCESS);
}

function* getGroupSavings() {
  const response = yield call([api, "get"], savingRequest.GROUP_SAVING);

  yield call(successHandler, response, types.GET_GROUP_SAVING_SUCCESS);
}

function* getTargetSavings() {
  const response = yield call([api, "get"], savingRequest.GROUP_TARGET);

  yield call(successHandler, response, types.GET_GROUP_TARGET_SUCCESS);
}

function* createIndividualSavings({ payload }) {
  const response = yield call(
    [api, "post"],
    savingRequest.INDIVIDUAL_SAVING,
    payload
  );

  yield call(successHandler, response, types.CREATE_INDIVIDUAL_SAVING_SUCCESS);
}

function* createGroupSaving({ payload }) {
  const response = yield call(
    [api, "post"],
    savingRequest.GROUP_SAVING,
    payload
  );

  yield call(successHandler, response, types.CREATE_GROUP_SAVING_SUCCESS);
}

function* createTargetSaving({ payload }) {
  const response = yield call(
    [api, "post"],
    savingRequest.GROUP_TARGET,
    payload
  );

  yield call(successHandler, response, types.CREATE_GROUP_TARGET_SUCCESS);
}

function* inviteGroupMembers({ payload }) {
  const response = yield call(
    [api, "post"],
    `${savingRequest.GROUP_SAVING}/${payload.id}/participants`,
    payload
  );

  yield call(
    successHandler,
    response,
    types.INVITE_GROUP_MEMBERS_SUCCESS,
    "You have added new users to the group savings plan"
  );
}

function* inviteTargetMembers({ payload }) {
  const response = yield call(
    [api, "post"],
    `${savingRequest.GROUP_TARGET}/${payload.id}/participants`,
    payload
  );

  yield call(
    successHandler,
    response,
    types.INVITE_TARGET_MEMBERS_SUCCESS,
    "You have added new users to the target savings plan"
  );
}

function* getGroupInvites() {
  const response = yield call([api, "get"], savingRequest.PENDING_INVITES);

  yield call(successHandler, response, types.VIEW_GROUP_INVITE_SUCCESS);
}

function* getTargetInvites() {
  const response = yield call(
    [api, "get"],
    savingRequest.PENDING_TARGET_INVITES
  );

  yield call(successHandler, response, types.VIEW_TARGET_INVITE_SUCCESS);
}

function* acceptOrDeclineGroupRequest({ payload }) {
  const response = yield call(
    [api, "post"],
    `group-savings/join-group`,
    payload
  );

  yield call(
    successHandler,
    response,
    types.ACCEPT_OR_DECLINE_GROUP_INVITE_SUCCESS,
    payload.status
      ? "Group joined successful"
      : "Group invite decline succesful"
  );
}

function* getSingleAcceptedInvites({ payload }) {
  const response = yield call(
    [api, "get"],
    `/group-savings/${payload}/participant`
  );

  yield call(successHandler, response, GET_SINGLE_ACCEPTED_GROUP_SUCCESS);
}

function* getSingleAcceptedTargetInvites({ payload }) {
  const response = yield call(
    [api, "get"],
    `/target-group-savings/${payload}/participant`
  );

  yield call(successHandler, response, GET_SINGLE_ACCEPTED_TARGET_SUCCESS);
}

function* getAllAcceptedInvites() {
  const response = yield call([api, "get"], `/group-savings/joined-groups/all`);

  yield call(successHandler, response, types.GET_ALL_ACCEPTED_GROUP.success);
}

function* getAllAcceptedTargetInvites() {
  const response = yield call(
    [api, "get"],
    "/target-group-savings/joined-groups/all"
  );

  yield call(successHandler, response, types.GET_ALL_ACCEPTED_TARGET.success);
}

function* getPayOutStatus({ payload }) {
  const response = yield call(
    [api, "get"],
    `/group-savings/${payload}/payout-status`
  );

  yield call(successHandler, response, GET_PAYOUT_STATUS_SUCCESS);
}

export default function* savingSaga() {
  yield takeLatest(
    types.GET_SAVINGS_REQUEST,
    safeSaga(getIndividualSavings, types.GET_SAVINGS_ERROR)
  );
  yield takeLatest(
    types.GET_GROUP_SAVING_REQUEST,
    safeSaga(getGroupSavings, types.GET_GROUP_SAVING_ERROR)
  );
  yield takeLatest(
    types.GET_GROUP_TARGET_REQUEST,
    safeSaga(getTargetSavings, types.GET_GROUP_TARGET_ERROR)
  );
  yield takeLatest(
    types.CREATE_GROUP_TARGET_REQUEST,
    safeSaga(createTargetSaving, types.CREATE_GROUP_TARGET_ERROR)
  );
  yield takeLatest(
    types.CREATE_INDIVIDUAL_SAVING_REQUEST,
    safeSaga(createIndividualSavings, types.CREATE_INDIVIDUAL_SAVING_ERROR)
  );
  yield takeLatest(
    types.CREATE_GROUP_SAVING_REQUEST,
    safeSaga(createGroupSaving, types.CREATE_GROUP_SAVING_ERROR)
  );
  yield takeLatest(
    types.INVITE_GROUP_MEMBERS_REQUEST,
    safeSaga(inviteGroupMembers, types.INVITE_GROUP_MEMBERS_ERROR)
  );

  yield takeLatest(
    types.INVITE_TARGET_MEMBERS_REQUEST,
    safeSaga(inviteTargetMembers, types.INVITE_TARGET_MEMBERS_ERROR)
  );
  yield takeLatest(
    types.VIEW_GROUP_INVITE_REQUEST,
    safeSaga(getGroupInvites, types.VIEW_GROUP_INVITE_ERROR)
  );
  yield takeLatest(
    types.VIEW_TARGET_INVITE_REQUEST,
    safeSaga(getTargetInvites, types.VIEW_TARGET_INVITE_ERROR)
  );
  yield takeLatest(
    types.ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST,
    safeSaga(
      acceptOrDeclineGroupRequest,
      types.ACCEPT_OR_DECLINE_GROUP_INVITE_ERROR
    )
  );
  yield takeLatest(
    GET_SINGLE_ACCEPTED_GROUP_REQUEST,
    safeSaga(getSingleAcceptedInvites, GET_SINGLE_ACCEPTED_GROUP_ERROR)
  );
  yield takeLatest(
    types.GET_SINGLE_ACCEPTED_TARGET_REQUEST,
    safeSaga(
      getSingleAcceptedTargetInvites,
      types.GET_SINGLE_ACCEPTED_TARGET_ERROR
    )
  );
  yield takeLatest(
    types.GET_ALL_ACCEPTED_GROUP.request,
    safeSaga(getAllAcceptedInvites, types.GET_ALL_ACCEPTED_GROUP.error)
  );
  yield takeLatest(
    types.GET_ALL_ACCEPTED_TARGET.request,
    safeSaga(getAllAcceptedTargetInvites, types.GET_ALL_ACCEPTED_TARGET.error)
  );
  yield takeLatest(
    GET_PAYOUT_STATUS_REQUEST,
    safeSaga(getPayOutStatus, GET_PAYOUT_STATUS_ERROR)
  );
}
