import React, { useEffect, useState } from "react";
import {
  GroupSavingsFormStage,
  SavingDetails,
} from "components/templates/NewSavingTemplate";
import { StageMapperValue } from "./SavingsTypes";
import { NewSavingsContainer } from "./NewSavingsContainer";
import { toast } from "react-toastify";
import { createGroupSaving } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "redux/store";

function NewGroupSavings() {
  const dispatch = useDispatch();

  const formStagesMapper: Record<GroupSavingsFormStage, StageMapperValue> = {
    name: [null, "addMembers"],
    addMembers: ["name", "confirmModal"],
    confirmModal: ["addMembers", "confirm"],
    confirm: ["confirmModal", null],
  };
  const [createSuccessfully, setcreateSuccessfully] = useState(false);
  const createSavingState = useSelector(
    (state: RootReducer) => state.saving.createGroupSaving
  );

  useEffect(() => {
    if (createSavingState.success) {
      setcreateSuccessfully(true);
      toast.success("Plan has been created");
    }
  }, [createSavingState.success]);

  //handles errors creating the saving plan
  useEffect(() => {
    if (
      Object.keys(createSavingState.errors).length !== 0 &&
      createSavingState.errors.constructor !== Object
    ) {
      toast.error(createSavingState.errors);
    }
  }, [createSavingState.errors]);

  const handleNextPageIsEmpty = (values) => {
    // prepare values for backend
    // return alert('Submitting');
    const members: any = localStorage.getItem("members");
    const payload = JSON.parse(members);
    const sendToDB = {
      name: values.name,
      amount: values.target,
      plan: values.frequency,
      day_of_month: values.dayOfMonth ? values.dayOfMonth : "",
      day_of_week: values.dayOfWeek ? values.dayOfWeek : "",
      hour_of_day: values.hourOfDay === -1 ? 1 : values.hourOfDay,
      no_of_participants: Number(values.members),
      description: "Nothing here",
      start_date: values.startDate?.format("YYYY-MM-DD"),
      end_date: values.endDate?.format("YYYY-MM-DD"),
      callback_url: window.location.origin,
      data: payload,
    };

    dispatch(createGroupSaving(sendToDB));
    toast.info("Processing please wait...");
  };

  const newGroupSavings: SavingDetails = {
    savingType: "Group",
    description: "Give your cooperative group saving plan a name",
    savingPlaceholder: "MBA Buddies",
    target: "Set individual contribution",
    frequency: "Set saving frequency",
  };

  return (
    <NewSavingsContainer
      savingDetails={newGroupSavings}
      formStagesMapper={formStagesMapper}
      onNextPageIsEmpty={handleNextPageIsEmpty}
      isCreateSuccessful={createSuccessfully}
      Id={createSavingState.data.id}
    />
  );
}

export default NewGroupSavings;
