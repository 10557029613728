import React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import purpleFlower from "assets/images/purpleFlower.svg";
import greenFlower from "assets/images/greenFlower.svg";
import {LIGHT_GREEN, MAIN_GREEN} from "styles/__color";
import {ReactComponent as IconPerson} from "assets/images/iconperson.svg";
import {ReactComponent as IconPeople} from "assets/images/iconpeople.svg";

interface SavingTypeInfoWrapperProps {
    iconColor: string;
    cardColor: string;
    activeTextColor: string;
}

interface SpecificSavingTypeProps {
    typeInfo: string;
    numberOfActive: number;
    onClick?: () => void;
}

interface SavingTypeInfoProps
    extends SavingTypeInfoWrapperProps,
        SpecificSavingTypeProps {
    title: string;
    iconNode?: React.ReactNode;
}

const commonCardStyles = css`
  height: 17.5rem;
  border-radius: 20px;
  min-width: 49.238%;
  padding: 2.6rem 3rem 4.1rem 4rem;
  cursor: pointer;
`;

function SavingTypeInfo(props: SavingTypeInfoProps) {
    const {title, typeInfo, numberOfActive, iconNode, ...wrapperProps} = props;

    return (
        <SavingTypeInfo.Wrapper {...wrapperProps}>
            <div className="info">
                <h5>{title}</h5>
                <div className="icon"> {iconNode} </div>
            </div>
            <div>
                <div className="type-info">{typeInfo}</div>

                <div className="active">{numberOfActive} Active</div>
            </div>
        </SavingTypeInfo.Wrapper>
    );
}

SavingTypeInfo.Wrapper = styled.div<SavingTypeInfoWrapperProps>`
  ${commonCardStyles};

  transition: 200ms ease-in-out;

  background-color: ${(props) => props.cardColor};
  border: 1px solid transparent;
  .icon {
    height: 5.5rem;
    width: 5.5rem;
    background-color: ${(props) => props.iconColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
  }

  .type-info {
    color: #33277b;
    font-weight: bold;
    font-size: 2rem;
    font-family: Graphie;
  }
  .active {
    color: ${(props) => props.activeTextColor};
    font-weight: bold;
    font-size: 1.6rem;
  }
  .info:first-of-type {
    margin-bottom: 3.2rem;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: red;
      font-family: Muli-SemiBold;
      color: #1e205c;
      opacity: 0.9;
      font-size: 1.5rem;
    }
  }

  &:hover {
    border: solid 1px ${(props) => props.iconColor};
  }
  /* transition: 300ms cubic-bezier() */
`;

export function IndividualSavingCard(props: SpecificSavingTypeProps) {
    return (
        <SavingTypeInfo
            {...props}
            onClick={props.onClick}
            title="Individual Savings"
            activeTextColor="#059157"
            cardColor="#e6ffea"
            iconColor="#51459E"
            iconNode={<IconPerson/>}
        />
    );
}

export function GroupSavingCard(props: SpecificSavingTypeProps) {
    return (
        <SavingTypeInfo
            {...props}
            title="Cooperative Group Savings"
            activeTextColor="#476ac9"
            cardColor="#F2F5FF"
            iconColor="#08CB7B"
            iconNode={<IconPeople/>}
        />
    );
}



export function GroupTargetSavingCard(props: SpecificSavingTypeProps) {
  return (
      <SavingTypeInfo
          {...props}
          title="Target Group Savings"
          activeTextColor="#476ac9"
          cardColor={LIGHT_GREEN}
          iconColor="#08CB7B"
          iconNode={<IconPeople/>}
      />
  );
}


interface SpecificSelectSavingsType {
    onClick: () => void;
}

interface SelectSavingType extends SpecificSelectSavingsType {
    title: string;
    description: string;
    cardColor: string;
    svg: string;
    hoverColor: string;
}

function SelectSavingType(props: SelectSavingType) {
    const {onClick, cardColor, title, description, svg, hoverColor} = props;

    return (
        <SelectSavingType.Wrapper
            onClick={onClick}
            cardColor={cardColor}
            svg={svg}
            hoverColor={hoverColor}
        >
            <div className="bg">
                <div className="text">
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
        </SelectSavingType.Wrapper>
    );
}

type SelectSavingTypeWrapper = {
    cardColor: string;
    svg: string;
    hoverColor: string;
};

SelectSavingType.Wrapper = styled.div<SelectSavingTypeWrapper>`
  ${commonCardStyles};
  background: ${(props) => props.cardColor};
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  border: 0.3px solid transparent;
  /* transition:all 0.3s ease-in-out; */
  transition: 200ms ease-in-out;

  &:hover {
    border: 0.3px ${(props) => props.hoverColor} solid;
    box-shadow: 0px 3px 6px #0000000d;
  }
  h5 {
    margin-bottom: 1rem;
    font: normal normal 600 2rem/4rem Graphie;
    color: #33277b;
  }

  p {
    font: normal normal normal 1.6rem/0 Muli;
    letter-spacing: 0px;
    color: #726b99;
  }

  /* &:hover { */
  .bg {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent url(${(props) => props.svg}) 0% 0% no-repeat
      padding-box;
    .text {
      margin: 3rem;
      margin-top: 0;
    }
  }
`;

export function IndividualSelectSavingType(props: SpecificSelectSavingsType) {
    return (
        <SelectSavingType
            {...props}
            title="Individual Savings"
            description="Personal savings at your disposal"
            cardColor=" #E6FFEA 0% 0% no-repeat padding-box"
            hoverColor={MAIN_GREEN}
            svg={greenFlower}
        />
    );
}

export function GroupSelectSavingType(props: SpecificSelectSavingsType) {
    return (
        <SelectSavingType
            {...props}
            title="Group Cooperative savings | esusu"
            description="Build wealth, save with friends & family"
            cardColor="#E8EAFF 0% 0% no-repeat padding-box"
            hoverColor="#33277B"
            svg={purpleFlower}
        />
    );
}

export function GroupTargetSelectSavingType(props: SpecificSelectSavingsType) {
    return (
        <SelectSavingType
            {...props}
            title="Group Target Savings"
            description="Build wealth, save with friends & family"
            cardColor="#E8EAFF 0% 0% no-repeat padding-box"
            svg={purpleFlower}
            hoverColor="#33277B"
        />
    )
}
