import React from "react";
import styled from "@emotion/styled";
import mailIcon from "assets/icons/Mail.svg";
import { Link } from "react-router-dom";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import { SIGNUP } from "../pagesPath";

const EmailSent = () => {
  return (
    <EmailSent.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="mail">
            <img src={mailIcon} alt="mail" className="mail-icon" />
          </div>
          <div className="title">
            <div className="primary-heading">
              Email
              <span className="secondary-heading"> verification</span>
            </div>

            <div className="sm-heading">
              Please check your mail inbox and click on the link received to
              verify your adashi account
            </div>
          </div>
        </div>
      </AuthCard>
      <div className="footer">
        <Link to={SIGNUP} className="footer-primary">
          Wrong Email Address ?
        </Link>
        <div>
          Did not get an email ?
          <Link to="" className="footer-primary">
            Resend Email
          </Link>
        </div>
      </div>
    </EmailSent.Wrapper>
  );
};

EmailSent.Wrapper = styled.div`
  .main {
    padding: 0 3rem;
    .mail {
      display: flex;
      justify-content: center;
      .mail-icon {
        width: 20rem;
      }
    }
    .title {
      margin: 2.5rem auto;
      text-align: center;
      .primary-heading {
        font-size: 2rem;
        font-weight: bold;
        color: #059157;
      }
      .secondary-heading {
        color: #47486b;
        font-style: italic;
      }
      img {
        height: 2.5rem;
      }
    }
    .sm-heading {
      margin: 1.5rem 0;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #080b4d;
      opacity: 0.6;
    }
    .forgot-password {
      color: #08cb7b;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 2rem 0;
      text-decoration: none;
    }
  }

  .footer {
    width: 33%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 2rem auto;
    font-size: 1.3rem;
    .footer-primary {
      /* font-weight: bold; */
      color: #08cb7b;
      text-decoration: none;
    }
  }
`;
export default EmailSent;
