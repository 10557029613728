export const authRequest = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  USER: "/user",
  UPLOAD_IMAGE: "/images",
  FOGOT_PASSWORD: "/password/forgot",
  RESET_PASSWORD: "/password/reset",
  VERIFY_USER_TOKEN: "/verify",
  RESEND_TOKEN: "verify/resend",
  UPDATE_PASSWORD: "user/password",
};
export const savingRequest = {
  INDIVIDUAL_SAVING: "/saving-plans",
  GROUP_SAVING: "/group-savings",

  // GROUP_COOP: "/target-group-savings",
  GROUP_TARGET: "/target-group-savings",
  PENDING_INVITES: `/group-savings/pending/invitations`,
  PENDING_TARGET_INVITES: `/target-group-savings/pending/invitations`,
  GROUP_PARTICIPANTS: `/group-savings/:id/participants`,
  TARGET_PARTICIPANTS: `/target-group-savings/:id/participants`,
  // INDIVIDUAL_COMMISSION: "/group-savings/commission",
  // GROUP_COMMISSION: "/savings-plan/commission",
};
export const paymentRequest = {
  INITIALIZE: "/cards/initialize",
  CARDS: "/cards",
  BANKS: "/bank-details/banks",
  BANK_DETAILS: "/bank-details",
  WITHDRAWAL: "/withdrawals",
};
export const transactionRequest = {
  TRANSACTIONS: "/transactions",
  TRANSACTIONS_SORT: "/transactions?sort_by_day=:transType",
  TRANSACTIONS_FILTER:
    "/transactions?filter[transaction_type]=:transType&page[size]=:pageSize&sort=-created_at",
  TRANSACTIONS_FILTERS:
    "transactions?filter[transaction_type]=&page[size]=5&sort=-created_at",
};
