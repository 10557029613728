import React, { useState } from "react";
import styled from "@emotion/styled";
import { Heading } from "components/typographpy/Heading";
import BackButton from "components/BackButton";
import CustomButton from "components/atoms/CustomButton";
import Input from "components/Input";
import { ConfirmIndividualPlanCard } from "components/ConfirmPlan";
import Dropdown from "components/Dropdown";
import { Tab, TabItem } from "components/Tab";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import shortid from "shortid";
import { IndividualSavings } from "components/ConfirmPlan/sharedType";
import { ReactComponent as HintIcon } from "assets/icons/hint-icon.svg";
import { Modal } from "components/Modal";
import successMark from "assets/images/successMark.svg";
import { formatNumber } from "utils/constants";
import moment from "moment";
import InviteMembers from "./InviteMemebers";
import { MAIN_GREEN } from "../../styles/__color";

export type FormStage = "name" | "confirmModal" | "confirm";
export type TargetStage = "name" | "confirmModal" | "confirm" | "addMembers";
export type GroupSavingsFormStage =
  | "name"
  | "addMembers"
  | "confirmModal"
  | "confirm";
export type GroupSavingsCoopStage = TargetStage;
export type IndividualSavingsFormStage = FormStage;

export type CardType = {
  id: string;
  card_type: string;
  last4: string;
};
export type SavingDetails = {
  savingType: "Individual" | "Group" | "Target";
  description: string;
  savingPlaceholder: string;
  frequency: string;
  target: string;
};

type NewSavingTemplateProps = {
  currentStage:
    | IndividualSavingsFormStage
    | GroupSavingsFormStage
    | GroupSavingsCoopStage;
  values: IndividualSavings;
  onValueChange: (e) => void;
  onContinue: () => void;
  onAddCard: () => void;
  onBack: () => void;
  cards: CardType[];
  createSuccessfully: boolean;
  onSuccess: () => void;
  savingDetails: SavingDetails;
};

type HintProps = {
  hint: string;
};

function Hint(props: HintProps) {
  const { hint } = props;

  return (
    <Hint.Wrapper>
      <div className="card">
        <div className="icon">
          <HintIcon />
        </div>
        <p>{hint}</p>
      </div>
    </Hint.Wrapper>
  );
}

Hint.Wrapper = styled.div`
  padding-bottom: 2.1rem;
  padding-top: 3.3rem;
  width: 70.1rem;
  font-size: 1.6rem;
  p {
    max-width: 253px;
  }
  .card {
    width: 63.053%;
    display: flex;
    justify-content: space-between;
    background-color: pink;
    padding-left: 3.2rem;
    padding-right: 8.2rem;
    padding-top: 4.9rem;
    height: 15.1rem;
    border-radius: 20px;
    background: #eff2ff url(/hint.svg) 0% 0% no-repeat padding-box;
    border: 1px solid #c0bed1;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

function NewSavingTemplate(props: NewSavingTemplateProps) {
  const {
    currentStage,
    onContinue,
    onAddCard,
    values,
    cards,
    onValueChange,
    onBack,
    createSuccessfully,
    onSuccess,
    savingDetails,
  } = props;

  const DAYS_OF_WEEK_OPTIONS = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];

  const HOUR_OF_DAY_OPTIONS = new Array(24).fill(null).map((_, index) => ({
    label: `${index}:00`,
    value: index,
  }));

  const DAY_OF_MONTH = new Array(28).fill(null).map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));

  let activateButton = !!values.name;

  // if (currentStage === "cycle") {
  //     activateButton = !!(
  //         values.startDate &&
  //         values.endDate &&
  //         values.members &&
  //         values.routineAmount
  //     );
  // } else
  //     if (currentStage === "name") {
  //     const frequencyIsValid =
  //         (values.frequency === "daily" && values.hourOfDay > -1) ||
  //         (values.frequency === "monthly" && values.dayOfMonth) ||
  //         (values.frequency === "weekly" && values.dayOfWeek > -1);
  //
  //     activateButton = !!(frequencyIsValid && values.target);
  // } else if (currentStage === "confirm") {
  //     activateButton = !!values.payment;
  // }

  if (savingDetails.savingType === "Target") {
    activateButton = !!(
      values.name &&
      values.target &&
      values.members &&
      values.startDate &&
      values.endDate
    );
  }

  const [focused, setfocused] = useState(false);
  const [endDateFocused, setEndDateFocused] = useState(false);

  const stagePercentMapper = {
    name: "30%",
    confirmModal: "75%",
    confirm: "100%",
  };

  const bannerTextMapper = {
    name: "Give your Individual savings plan a name",
    savingsTarget: "Set your savings target and frequency here",
    cycle: `Set your savings cycle here & see how much you'd be saving daily`,
  };
  const todayDate = moment();

  // https://stackoverflow.com/questions/54436535/how-to-show-only-specific-days-react-dates
  const blockTodaysDate = (momentDate) => {
    if (momentDate.diff(todayDate) < 1) {
      return true;
    }

    return false;
  };

  const blockTillStartDate = (momentDate) => {
    if (values.startDate && momentDate.diff(values.startDate) < 1) {
      return true;
    }

    return false;
  };

  const numberOfMembers = (type) => {
    return (
      <>
        <Heading type="h2">
          {" "}
          {type === "Target"
            ? "Choose number of members in the group"
            : "Add number of members are in the group"}
        </Heading>
        <div className="input-item">
          <Input
            onChange={onValueChange}
            type="text"
            name="members"
            placeholder="No of people in group"
            pattern="[0-9]"
            value={values.members}
          />
        </div>
      </>
    );
  };
  // console.log(savingDetails);

  return (
    <NewSavingTemplate.Wrapper
      percentComplete={stagePercentMapper[currentStage]}
    >
      <Heading type="h3">Registration</Heading>
      <section className="content">
        <main>
          <div className="tab-wrapper">
            <Tab>
              {savingDetails.savingType === "Individual" ? (
                <TabItem active>Individual Savings</TabItem>
              ) : savingDetails.savingType === "Group" ? (
                <TabItem active>Co-operative Group Savings</TabItem>
              ) : (
                <TabItem active>Target Group Savings</TabItem>
              )}
            </Tab>
          </div>

          <div className="green"></div>
          {currentStage === "name" && (
            <div className="form-wrapper">
              <Heading type="h2">{savingDetails.description}</Heading>
              <div className="input-item">
                <Input
                  onChange={onValueChange}
                  type="text"
                  name="name"
                  placeholder={savingDetails.savingPlaceholder}
                  value={values.name}
                />
              </div>
              <Heading type="h2">{savingDetails.target}</Heading>
              <div className="input-item">
                <Input
                  onChange={onValueChange}
                  type="text"
                  name="target"
                  placeholder="#500,000"
                  value={values.target}
                />
              </div>
              {savingDetails.savingType === "Individual"
                ? ""
                : numberOfMembers(savingDetails.savingType)}
              {savingDetails.savingType === "Target" ? (
                ""
              ) : (
                <div className="input-item">
                  <Heading type="h2">Set saving frequency</Heading>
                  <Dropdown
                    defaultText="Frequency"
                    dropDownItems={[
                      { label: "Daily", value: "daily" },
                      { label: "Monthly", value: "monthly" },
                      { label: "Weekly", value: "weekly" },
                    ]}
                    onSelect={(item) =>
                      onValueChange({
                        target: { name: "frequency", value: item.value },
                      })
                    }
                  />
                </div>
              )}

              {values.frequency && values.frequency === "daily" && (
                <div className="input-item">
                  <Dropdown
                    defaultText="Choose Hour of day"
                    dropDownItems={HOUR_OF_DAY_OPTIONS}
                    onSelect={(item) =>
                      onValueChange({
                        target: { name: "hourOfDay", value: item.value },
                      })
                    }
                  />
                </div>
              )}

              {values.frequency && values.frequency === "weekly" && (
                <div className="input-item">
                  <Dropdown
                    defaultText="Choose day of week"
                    dropDownItems={DAYS_OF_WEEK_OPTIONS}
                    onSelect={(item) =>
                      onValueChange({
                        target: { name: "dayOfWeek", value: item.value },
                      })
                    }
                  />
                </div>
              )}
              {values.frequency && values.frequency === "monthly" && (
                <div className="input-item">
                  <Dropdown
                    defaultText="Choose day of month."
                    dropDownItems={DAY_OF_MONTH}
                    onSelect={(item) =>
                      onValueChange({
                        target: { name: "dayOfMonth", value: item.value },
                      })
                    }
                  />
                </div>
              )}

              <Heading type="h2">Set saving cycle</Heading>
              <div className="input-item">
                <SingleDatePicker
                  date={values.startDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) =>
                    onValueChange({
                      target: { name: "startDate", value: date },
                    })
                  } // PropTypes.func.isRequired
                  focused={focused} // PropTypes.bool
                  onFocusChange={(curr) => setfocused(curr.focused)} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  placeholder="Start Date"
                  id={shortid.generate()}
                  isDayBlocked={blockTodaysDate}
                />
              </div>
              {values.startDate && (
                <div className="input-item">
                  <SingleDatePicker
                    date={values.endDate} // momentPropTypes.momentObj or null
                    placeholder="Maturity Date"
                    onDateChange={(date) =>
                      onValueChange({
                        target: { name: "endDate", value: date },
                      })
                    }
                    focused={endDateFocused} // PropTypes.bool
                    onFocusChange={(type) => setEndDateFocused(type.focused)} // PropTypes.func.isRequired
                    numberOfMonths={1}
                    id={shortid.generate()}
                    isDayBlocked={blockTillStartDate}
                  />
                </div>
              )}

              {values.startDate &&
                values.endDate &&
                savingDetails.savingType !== "Target" && (
                  <div className="input-item">
                    <div className="heading-wrapper">
                      <Heading type="h3">
                        Amount to be paid {values.frequency}
                      </Heading>
                    </div>

                    <Input
                      type="text"
                      name="routineAmount"
                      placeholder="Minimum of 1000"
                      value={formatNumber(
                        values?.routineAmount ? values?.routineAmount : ""
                      )}
                      disabled
                    />
                  </div>
                )}
            </div>
          )}

          {currentStage === "addMembers" && (
            <Modal>
              <div className="form-wrapper">
                {/*<Heading type="h3">Invite members (Name, Email, Phone number, Collection order)</Heading>*/}
                <InviteMembers
                  no_of_members={values.members}
                  members={values.members}
                  modal={true}
                  inviteHandler={onContinue}
                  closeModal={() => onBack()}
                />
              </div>
            </Modal>
          )}

          {/*



          -----


          */}
          {/*{currentStage === "savingsTarget" && (*/}
          {/*  <div className="form-wrapper">*/}
          {/*    <Heading type="h3">{savingDetails.target} </Heading>*/}
          {/*    <div className="input-item">*/}
          {/*      <Input*/}
          {/*        onChange={onValueChange}*/}
          {/*        type="text"*/}
          {/*        name="target"*/}
          {/*        placeholder="Set a target"*/}
          {/*        pattern="[0-9]"*/}
          {/*        value={values.target?.toString()}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className="heading-wrapper">*/}
          {/*      <Heading type="h3"> {savingDetails.frequency} </Heading>*/}
          {/*    </div>*/}

          {/*    <div className="input-item">*/}
          {/*      <Dropdown*/}
          {/*        defaultText="Frequency"*/}
          {/*        dropDownItems={[*/}
          {/*          { label: "Daily", value: "daily" },*/}
          {/*          { label: "Monthly", value: "monthly" },*/}
          {/*          { label: "Weekly", value: "weekly" },*/}
          {/*        ]}*/}
          {/*        onSelect={(item) =>*/}
          {/*          onValueChange({*/}
          {/*            target: { name: "frequency", value: item.value },*/}
          {/*          })*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </div>*/}

          {/*    {values.frequency && values.frequency === "daily" && (*/}
          {/*      <div className="input-item">*/}
          {/*        <Dropdown*/}
          {/*          defaultText="Choose Hour of day"*/}
          {/*          dropDownItems={HOUR_OF_DAY_OPTIONS}*/}
          {/*          onSelect={(item) =>*/}
          {/*            onValueChange({*/}
          {/*              target: { name: "hourOfDay", value: item.value },*/}
          {/*            })*/}
          {/*          }*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    )}*/}

          {/*    {values.frequency && values.frequency === "weekly" && (*/}
          {/*      <div className="input-item">*/}
          {/*        <Dropdown*/}
          {/*          defaultText="Choose day of week"*/}
          {/*          dropDownItems={DAYS_OF_WEEK_OPTIONS}*/}
          {/*          onSelect={(item) =>*/}
          {/*            onValueChange({*/}
          {/*              target: { name: "dayOfWeek", value: item.value },*/}
          {/*            })*/}
          {/*          }*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*    {values.frequency && values.frequency === "monthly" && (*/}
          {/*      <div className="input-item">*/}
          {/*        <Dropdown*/}
          {/*          defaultText="Choose day of month."*/}
          {/*          dropDownItems={DAY_OF_MONTH}*/}
          {/*          onSelect={(item) =>*/}
          {/*            onValueChange({*/}
          {/*              target: { name: "dayOfMonth", value: item.value },*/}
          {/*            })*/}
          {/*          }*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{(currentStage === "cycle" || currentStage === "confirmModal") && (*/}
          {/*    <div className="form-wrapper">*/}
          {/*        <Heading type="h2">Set your saving cycle</Heading>*/}
          {/*        <div className="input-item">*/}
          {/*            <SingleDatePicker*/}
          {/*                date={values.startDate} // momentPropTypes.momentObj or null*/}
          {/*                onDateChange={(date) =>*/}
          {/*                    onValueChange({*/}
          {/*                        target: {name: "startDate", value: date},*/}
          {/*                    })*/}
          {/*                } // PropTypes.func.isRequired*/}
          {/*                focused={focused} // PropTypes.bool*/}
          {/*                onFocusChange={(curr) => setfocused(curr.focused)} // PropTypes.func.isRequired*/}
          {/*                numberOfMonths={1}*/}
          {/*                placeholder="Start Date"*/}
          {/*                id={shortid.generate()}*/}
          {/*                isDayBlocked={blockTodaysDate}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*        {values.startDate && (*/}
          {/*            <div className="input-item">*/}
          {/*                <SingleDatePicker*/}
          {/*                    date={values.endDate} // momentPropTypes.momentObj or null*/}
          {/*                    placeholder="Maturity Date"*/}
          {/*                    onDateChange={(date) =>*/}
          {/*                        onValueChange({*/}
          {/*                            target: {name: "endDate", value: date},*/}
          {/*                        })*/}
          {/*                    }*/}
          {/*                    focused={endDateFocused} // PropTypes.bool*/}
          {/*                    onFocusChange={(type) => setEndDateFocused(type.focused)} // PropTypes.func.isRequired*/}
          {/*                    numberOfMonths={1}*/}
          {/*                    id={shortid.generate()}*/}
          {/*                    isDayBlocked={blockTillStartDate}*/}
          {/*                />*/}
          {/*            </div>*/}
          {/*        )}*/}
          {/*        {values.startDate && values.endDate && (*/}
          {/*            <div className="input-item">*/}
          {/*                <div className="heading-wrapper">*/}
          {/*                    <Heading type="h3">*/}
          {/*                        Amount to be paid {values.frequency}*/}
          {/*                    </Heading>*/}
          {/*                </div>*/}

          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    name="routineAmount"*/}
          {/*                    placeholder="Minimum of 1000"*/}
          {/*                    value={formatNumber(*/}
          {/*                        values?.routineAmount ? values?.routineAmount : ""*/}
          {/*                    )}*/}
          {/*                    disabled*/}
          {/*                />*/}
          {/*            </div>*/}
          {/*        )}*/}
          {/*    </div>//form-wrapper*/}
          {/*)}*/}

          {currentStage === "confirm" && (
            <div className="form-wrapper">
              <div className="pay-header">
                <Heading type="h3">Payment</Heading>
                <span className="pay" onClick={onAddCard}>
                  Add Card+
                </span>
              </div>
              <div className="input-item">
                {cards.length ? (
                  <Dropdown
                    defaultText="Select card"
                    dropDownItems={cards.map((card) => ({
                      label: `${card.card_type} card - ${card.last4}`,
                      value: card.id,
                    }))}
                    onSelect={(item) =>
                      onValueChange({
                        target: { name: "payment", value: item.value },
                      })
                    }
                  />
                ) : (
                  <p>Please Add a Card to proceed</p>
                )}
              </div>
              {createSuccessfully && (
                <Modal>
                  <div className="modal-content">
                    <img src={successMark} alt="successMark" />
                    <Heading type="h3">Success</Heading>
                    <p>
                      Your new {savingDetails.savingType} savings plan was set
                      successfully
                    </p>
                    <CustomButton
                      size="big"
                      backgroundColor="#33277B"
                      onClick={onSuccess}
                    >
                      Go to plan
                    </CustomButton>
                  </div>
                </Modal>
              )}
            </div>
          )}

          <div className="controls">
            <BackButton onClick={onBack} />
            <CustomButton onClick={onContinue} disabled={!activateButton}>
              Continue
            </CustomButton>
          </div>
        </main>
        {currentStage !== "confirm" && (
          <Hint hint={bannerTextMapper[currentStage]} />
        )}

        {currentStage === "confirm" && (
          <div className="confirm-card-wrapper">
            <ConfirmIndividualPlanCard
              inlineConfirmation
              cardWidth="75%"
              values={values}
              hideBtnSection
            />
          </div>
        )}
      </section>
      <aside></aside>
    </NewSavingTemplate.Wrapper>
  );
}

NewSavingTemplate.Wrapper = styled.div<{ percentComplete: string }>`
  padding-top: calc(10.907vh - 1rem);

  .tab-wrapper,
  .confirm-card-wrapper {
    padding-bottom: 2.1rem;
    padding-top: 3.3rem;
    z-index: -1;
  }
  .confirm-card-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 763px;
    padding-right: 10%;
  }
  aside {
    background-color: orange;
    min-height: 100vh;

    background-color: #ffffff;
    box-shadow: 0px 50px 90px #00000003;
    opacity: 1;
    padding: 0 3.6534%;
    padding-top: calc(5.907vh - 1rem);
    .card {
      background: #52469e url("/purple-card.svg") 0% 0% no-repeat border-box;
      background-size: 100% 100%;
      background-position-y: 2rem;
      border-radius: 34px;
      width: 100%;
      height: 24.4rem;
      padding: 50px;
      font-size: 2rem;
      color: #7cefc0;
      p {
        font-family: Graphie;
        font-weight: bold;
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 3rem;
        }
      }
    }
    .main-content-wrapper {
      margin-top: 2rem;

      width: 100%;
      margin-bottom: 1rem;
      > * {
        margin-bottom: 2.2rem;
      }

      .title {
        font-size: 2rem;
        display: flex;

        align-items: center;
      }

      .icon-wrapper {
        border: solid 1px ${MAIN_GREEN};
        width: 2.6rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px #00000005;
        border: 1px solid #08cb7b;
        border-radius: 6px;
        margin-left: 1rem;
      }
    }
  }
  .green {
    width: ${(props) => props.percentComplete};
    height: 5px;
    background: #08cb7b 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-bottom: 5rem;
    transition: width 300ms;
  }

  .form-wrapper {
    .heading-wrapper {
      margin-top: 33px;
    }
    .pay-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-family: Muli-Bold;
        font-size: 1.8rem;
        color: #47486b;
        cursor: pointer;
      }
    }
  }
  .modal-content {
    background-color: #ffffff;
    border-radius: 2rem;
    padding: 4.5rem 7rem;
    text-align: center;
    h3 {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.6rem;
      margin: auto;
      width: 80%;
      margin-bottom: 2rem;
      color: #726b99;
    }
  }
  section.content {
    display: flex;
    justify-content: space-between;
    main {
      margin-right: 20px;
      min-width: 30%;
    }
  }
  h2 {
    margin: 2.2rem 0rem;
  }
  h3 {
    margin-bottom: 2.2rem;
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 33px;
  }

  .input-item {
    .SingleDatePicker,
    .SingleDatePicker_1 {
      width: 100%;
      border-radius: 15px;
    }
    .SingleDatePickerInput,
    .SingleDatePickerInput_1,
    .SingleDatePickerInput__withBorder,
    .SingleDatePickerInput__withBorder_2 {
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
    }
    .DateInput_input,
    .DateInput_input_1 {
      font-family: Muli-Bold;
      color: gray;
      font-weight: normal;
      font-size: 1.6rem;
      font-family: Muli-Bold;
      /* color: #059157; */
      width: 141%;
      padding-left: 2rem;
    }
  }
  @media screen and (max-width: 960px) {
    section.content {
      flex-direction: column;
      main {
        width: 100%;
        .input-item {
          margin-bottom: 1.5rem;
        }
      }
      .confirm-card-wrapper {
        padding-right: 0;
        justify-content: normal;
        width: 100%;
      }
    }
    .modal-content {
      width: 90%;
    }
  }
`;

export default NewSavingTemplate;
