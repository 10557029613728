import { defaultSingleObjectArrayState } from "utils/constants";
import { extractStatus, handleFetch } from "helpers";
import * as types from "redux/types";

const defaultPayload = {
  data: [],
  errors: [],
};
const initalState = {
  all: defaultSingleObjectArrayState,
  daily: defaultSingleObjectArrayState,
  weekly: defaultSingleObjectArrayState,
  monthly: defaultSingleObjectArrayState,
};

const savingReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);

  switch (type) {
    case types.GET_ALL_TRANSACTIONS.request:
    case types.GET_ALL_TRANSACTIONS.success:
    case types.GET_ALL_TRANSACTIONS.error:
      return handleFetch(state, status, payload, "all");
    case types.GET_DAILY_TRANSACTIONS.request:
    case types.GET_DAILY_TRANSACTIONS.success:
    case types.GET_DAILY_TRANSACTIONS.error:
      return handleFetch(state, status, payload, "daily");
    case types.GET_WEEKLY_TRANSACTIONS.request:
    case types.GET_WEEKLY_TRANSACTIONS.success:
    case types.GET_WEEKLY_TRANSACTIONS.error:
      return handleFetch(state, status, payload, "weekly");
    case types.GET_MONTHLY_TRANSACTIONS.request:
    case types.GET_MONTHLY_TRANSACTIONS.success:
    case types.GET_MONTHLY_TRANSACTIONS.error:
      return handleFetch(state, status, payload, "monthly");
    default:
      return state;
  }
};

export default savingReducer;
