import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import { BACKGROUND } from "styles/__color";
import { DownArrow, LogoutButton } from "components/Icon";
import profileImg from "assets/icons/profileIcon.svg";
import logoutImg from "assets/icons/logoutIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "redux/store";
import { logoutRequest } from "redux/actions";
import FlexBox from "styles/FlexBox";
import { ACCOUNT } from "pages/pagesPath";
import ProfileImg from "./molecules/ProfileImg";
import logo from "assets/images/logo2.png";
// import hamburgerIcon from "assets/images/landingPage/icon-hamburger.svg";
interface MenuOptions {
  label: string;
  icon: any;
  path: string;
}

interface SideBarOption extends MenuOptions {
  submenu?: MenuOptions[];
}

interface SideBarProps {
  pages: SideBarOption[];
  hideSideBar: () => void;
}

interface MenuItemProps {
  page: MenuOptions;
  isActive?: boolean;
  isSubMenu?: boolean;
  onClick: (page: MenuOptions) => void;
  withBorder?: boolean;
  withQuickActionStyles?: boolean;
}

export const MenuItem = (props: MenuItemProps) => {
  const {
    page,
    withBorder = false,
    withQuickActionStyles = false,
    isSubMenu = false,
    onClick,
    isActive = false,
  } = props;

  const { label, icon: Icon } = page;

  return (
    <MenuItem.Wrapper
      isActive={isActive}
      isSubMenu={isSubMenu}
      withBorder={withBorder}
      onClick={() => onClick(page)}
      withQuickActionStyles={withQuickActionStyles}
    >
      <div className="icon">
        <Icon width="20px" height="20px" />
      </div>
      <span> {label}</span>
    </MenuItem.Wrapper>
  );
};

type MenuItemWrapperProps = {
  isActive: boolean;
  isSubMenu?: boolean;
  withBorder: boolean;
  withQuickActionStyles: boolean;
};

MenuItem.Wrapper = styled.span<MenuItemWrapperProps>`
  padding: 15.6px 15px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin: 1rem 0;
  font-family: Muli-SemiBold;
  font-size: 1.6rem;
  border-radius: 31px;
  span {
    margin-left: 16px;
  }
  .icon {
    margin-left: 1rem;
  }
  &:hover {
    background-color: #fff;
    cursor: pointer;

    box-shadow: 0px 20px 30px #0000000d;
  }

  ${(props) =>
    props.isSubMenu &&
    css`
      padding-left: 32px;
    `}

  ${(props) =>
    props.isActive &&
    css`
      background-color: #fff;
      color: #059157;
      cursor: pointer;
      font-family: Muli-Bold;
      border-radius: 31px;
      box-shadow: 0px 20px 30px #0000000d;
    `}
    ${(props) =>
    props.withBorder &&
    css`
      border: 0.2rem solid #efefef;
    `}

    ${(props) =>
    props.withQuickActionStyles &&
    css`
      text-align: left;
      font: normal normal 600 16px/20px Muli;
      letter-spacing: 0px;
      color: rgb(30 32 92 / 80%);
    `}
`;

interface SubMenuProps extends Omit<MenuOptions, "path"> {
  pages: Array<SideBarOption>;
  onSubMenuClick: (page: MenuOptions) => void;
}

const SubMenu = (props: SubMenuProps) => {
  const [visible, setVisible] = useState(false);
  const { icon, label, pages, onSubMenuClick } = props;
  const itemPage = {
    label,
    icon,
    path: "",
  };

  const currentLocation = window.location.pathname;

  return (
    <>
      <MenuItem page={itemPage} onClick={() => setVisible(!visible)} />
      <SubMenu.Wrapper
        visible={visible}
        className={`${visible ? "is-visible" : ""}`}
      >
        {pages.map((page, index) => {
          return (
            <MenuItem
              isActive={currentLocation.includes(page.path)}
              key={index}
              isSubMenu
              page={page}
              onClick={onSubMenuClick}
            />
          );
        })}
      </SubMenu.Wrapper>
    </>
  );
};

type SubMenuWrapper = {
  visible: boolean;
};
SubMenu.Wrapper = styled.div<SubMenuWrapper>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.visible &&
    css`
      display: none;
    `}
`;

const useClickOutside = (handler) => {
  const domNode = useRef<any>();

  useEffect(() => {
    const maybeHandler = (event: any) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

// const HamburgerWrapper = styled.div`
//   display: none;
//   @media screen and (max-width: 960px) {
//     display: block;
//     padding: 3rem 3rem 0 3rem;
//     text-align: end;
//     cursor: pointer;
//   }
// `;

const SideBar = (props: SideBarProps) => {
  const { pages, hideSideBar } = props;
  const history = useHistory();
  const currentLocation = window.location.pathname;

  const menuItemClickHandler = (page: MenuOptions) => {
    history.push(page.path);
    hideSideBar();
  };

  const auth = useSelector((state: RootReducer) => state.auth.user.data);
  const [displayLogout, setDisplayLogout] = useState(false);
  const dispatch = useDispatch();
  const domNode = useClickOutside(() => {
    setDisplayLogout(false);
  });

  return (
    <SideBar.Wrapper>
      {/* <HamburgerWrapper>
          <img src={hamburgerIcon} alt="" onClick={hideSideBar} />
        </HamburgerWrapper> */}
      <img src={logo} alt="logo" className="logo" />
      {/* <h3>Adashi</h3> */}

      <div className="img-holder">
        {/* <div className="image">
          <img src={profile} alt="logo" />
        </div> */}
        <ProfileImg
          imageURL={auth.user_profile.avatar}
          showDefaultImage={auth.user_profile.avatar === null}
        />
        <FlexBox className="user-name">
          <h4> {auth.name} </h4>
          <DownArrow
            className="fas fa-arrow-down"
            onClick={() => setDisplayLogout(!displayLogout)}
          />
        </FlexBox>
        <div
          ref={domNode}
          className={`${displayLogout ? "active" : ""} action-btn`}
        >
          <FlexBox
            onClick={() => {
              history.push(ACCOUNT);
              setDisplayLogout(false);
              hideSideBar();
            }}
          >
            <img src={profileImg} alt="profileImg" />
            <p>Profile</p>
          </FlexBox>
          <FlexBox
            onClick={() => {
              dispatch(logoutRequest());
              history.push("/");
            }}
          >
            <img src={logoutImg} alt="logoutimg" />
            <p>Logout</p>
          </FlexBox>
        </div>
      </div>
      <div>
        {pages.map((page, index) => {
          const { label, icon, submenu } = page;

          return submenu === undefined ? (
            <MenuItem
              isActive={currentLocation.includes(page.path)}
              page={page}
              key={index}
              onClick={menuItemClickHandler}
            />
          ) : (
            <SubMenu
              onSubMenuClick={menuItemClickHandler}
              key={page.label}
              label={label}
              icon={icon}
              pages={submenu}
            />
          );
        })}
        {
          <MenuItem
            page={{
              label: "Logout",
              icon: LogoutButton,
              path: "#",
            }}
            onClick={() => {
              dispatch(logoutRequest());
              history.push("/");
            }}
          />
        }
      </div>
    </SideBar.Wrapper>
  );
};

SideBar.Wrapper = styled.div`
  .logo {
    width: 8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  padding: 0 2rem;
  height: 80%;
  font-size: 13px;
  background: ${BACKGROUND};
  position: fixed;
  color: rgba(67, 85, 112, 0.72);
  display: flex;
  flex-direction: column;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: #444c64;
    font-size: 2.2rem;
    line-height: 19px;
    font-family: Muli-Bold;
    display: flex;
    align-items: center;
    margin-bottom: 55px;
  }
  .img-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .user-name {
      margin-bottom: 2rem;
      h4 {
        color: #47486b;
        font-size: 1.6rem;
      }
      .fa-arrow-down {
        margin-left: 1rem;
        color: #08cb7b;
        :hover {
          cursor: pointer;
        }
      }
    }

    .action-btn {
      position: absolute;
      display: none;
      top: 2rem;
      left: 7rem;
      /* z-index: 10; */
      background-color: #fff;
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: 0px 70px 90px #0000001a;
      p {
        font-family: Muli-SemiBold;
        color: #47486b;
        font-size: 1.5rem;
        :hover {
          color: #059157;
        }
      }
      img {
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 1rem;
      }
      div:last-child {
        margin-top: 2rem;
      }
      div {
        padding: 1rem;
        :hover {
          /* background: #83ff73; */
          cursor: pointer;
        }
      }
    }
    .active {
      display: block;
    }
  }
  .quick-action {
    margin-top: 8.75rem;
  }
  @media screen and (max-width: 960px) {
    z-index: 5;
    top: 0;
  }
`;
export default SideBar;
