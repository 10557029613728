import styled from "@emotion/styled";
import React from "react";
import { css } from "@emotion/core";

type HeadingType = {
  children: React.ReactNode;
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: string;
  family?:
    | "Graphie"
    | "Muli"
    | "Muli-SemiBold"
    | "Graphie-Bold"
    | "Muli-ExtraBold"
    | "Graphie-ExtraBold";
  opacity?: string;
  size?: string;
};

export const Heading = (props: HeadingType) => {
  const {
    type,
    children,
    color = "#33277B",
    family = "Graphie",
    opacity,
    size,
  } = props;
  const sizeMapper = {
    h3: "2.2rem",
    h5: "1.6rem",
  };
  const fontSize = size || sizeMapper[type] || "auto";

  const headingStyles = css`
    font-size: ${fontSize};
    line-height: 20px;
    font-family: ${family};
    color: ${color};
    opacity: ${opacity};
  `;

  const HeadingComponent = styled[type]`
    ${headingStyles}
  `;

  return <HeadingComponent>{children} </HeadingComponent>;
};
