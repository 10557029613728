import React, { useCallback } from 'react'


function AutoLogOut({ timeout, onTimeOut }: any) {
   

  const logout = useCallback(()=> {
   onTimeOut && onTimeOut()
  },[onTimeOut]) 

  React.useEffect(() => {

    let logoutTimeout;

    const setTimeouts = () => {
      logoutTimeout = setTimeout(logout, timeout);
    };

    const clearTimeouts = () => {
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();

    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    }
  }, [logout, timeout]);
  return <div></div>
}

export default AutoLogOut