import styled from '@emotion/styled'
import React from 'react'
import profile from "assets/images/profile.svg";
import profilePictureEditIcon from "assets/icons/Group 372.svg";

type ProfileImgProps = {
  canEdit?: boolean
  imageURL: string
  showDefaultImage: boolean
}

const ProfileImg = (props: ProfileImgProps) => {
  const { showDefaultImage = true, canEdit = false, imageURL } = props;

  return (
    <ProfileImg.Wrapper>
      {
        showDefaultImage ?
          <div className="image-holder">
            <img src={profile} alt="" className="default-img" />
            {
              canEdit &&
              <label htmlFor="actual-btn" className="edit-icon"><img src={profilePictureEditIcon} alt="edit-icon" /></label>
            }
          </div> :
          <div className="with-profile-image">
            <img src={imageURL} alt="profileImage" />
            {
              canEdit &&
              <label htmlFor="actual-btn" className="edit-icon"><img src={profilePictureEditIcon} alt="edit-icon" /></label>
            }
          </div>
      }
    </ProfileImg.Wrapper>
  )
}


ProfileImg.Wrapper = styled.div`
  .edit-icon {
      position: absolute;
      bottom:0;
      right: -10%;
      padding: 0.6rem;
      background-color: #08cb7b;
      cursor: pointer;
      border: 2px solid #E6FFEA;
      box-shadow: 0px 30px 50px #00000029;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
  }
  .image-holder {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    background-color:#E6FFEA;
    .default-img {
      position: absolute;
      bottom:0;
      left:40%;
    }
   
  }
  .with-profile-image{
    position: relative;
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
    img{
      border-radius: 1rem;
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
`
export default ProfileImg
