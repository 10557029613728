import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Button from "components/atoms/CustomButton";
import checkIcon from "assets/icons/SuccessMark.svg";
import { useHistory } from "react-router-dom";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import { LOGIN, RESEND_VERIFICATION } from "../pagesPath";
import AuthHeading from "components/typographpy/AuthHeading";
import { useDispatch, useSelector } from 'react-redux';
import { verifyUserTokenRequest } from 'redux/actions';
import { RootReducer } from 'redux/store';

const PasswordResetSuccess = () => {
  const history = useHistory();
  const url = new URL(window.location.href);
  const [token, email] = [url.searchParams.get('token'), url.searchParams.get('email')]
  const dispatch = useDispatch();
  const { processing, success } = useSelector(
    (state: RootReducer) => state.auth.user
  );

  useEffect(() => {
    dispatch(verifyUserTokenRequest(
      {
        email,
        token,
      }
    ))
  }, [dispatch, email, token]);

  return (
    <PasswordResetSuccess.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        {
          processing ? <h2>Verifing account</h2> :

            <div className="main">
              <div className="icon">
                {
                  success ? 
                <img src={checkIcon} alt="icon" className="check-icon" /> :
                <img src="https://img.icons8.com/color/96/000000/cancel--v1.png" alt="failed"/>
                }
              </div>
              <div className="title">
                <AuthHeading
                  primaryHeading={`Account Verification ${success ? 'Successful' : "Failed"} `}
                  secondaryHeading={success ? "Successful" : "Failed"}
                />
              </div>
              <div className="button">
                <Button
                  onClick={() => history.push(success ? LOGIN : RESEND_VERIFICATION)}
                >
                  {success ? "Login" : "Resend Email"}
                </Button>
              </div>
            </div>
        }
      </AuthCard>
    </PasswordResetSuccess.Wrapper>
  );
};

PasswordResetSuccess.Wrapper = styled.div`
  .icon {
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    .check-icon {
      width: 20rem;
      object-fit: cover;
    }
  }

  .title {
                  padding: 2rem 0;
    text-align: center;
  }
  .button {
                  padding: 1rem 0;
    text-align: center;
  }
`;
export default PasswordResetSuccess;
