import * as pagesPath from "../pagesPath";
import Login from "./Login";
import VerifyToken from "./VerifyToken";
import EmailSent from "./EmailSent";
import ResetPassword from "./ResetPassword";
import withSuspense from "components/HOC/WithSuspens";
import ResendVerification from "./ResendVerification";
import NewPassword from "./NewPassword";
import PasswordResetSuccess from "./PasswordResetSuccess";

export const publicRoutes = [
  {
    path: pagesPath.LOGIN,
    component: Login,
    // component: withSuspense({ page: "UnAuthPages/LoginPage" }),
    exact: true,
  },

  {
    path: pagesPath.EMAIL_SENT,
    component: EmailSent,
    exact: true,
  },
  {
    path: pagesPath.VERIFY_USER_TOKEN,
    component: VerifyToken,
    exact: true,
  },
  {
    path: pagesPath.RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
  },
  {
    path: pagesPath.RESEND_VERIFICATION,
    component: ResendVerification,
    exact: true,
  },
  {
    path: pagesPath.ENTER_NEW_PASSWORD,
    component: NewPassword,
    exact: true,
  },
  {
    path: pagesPath.PASSWORD_RESET_SUCCESS,
    component: PasswordResetSuccess,
    exact: true,
  },
  {
    path: pagesPath.GROUP,
    component: withSuspense({ page: "UnAuthPages/GroupSaving" }),
    exact: true,
  },
  {
    path: pagesPath.INDIVIDUAL,
    component: withSuspense({ page: "UnAuthPages/IndividualSaving" }),
    exact: true,
  },
  {
    path: "/",
    component: withSuspense({ page: "UnAuthPages/LandingPage" }),
    exact: true,
  },
];
