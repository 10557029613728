import styled from "@emotion/styled";
import { MAIN_GREEN } from "styles/__color";
import { css } from "@emotion/core";

interface CustomButtonProps {
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  secondary?: boolean;
  disabled?: boolean;
  size?: "big" | "medium" | "small";
  color?: string;
}

export const CustomButton = styled.button<CustomButtonProps>`
  display: inline-block;
  padding: 0.8rem 3.2rem;
  transition: all 0.5s;
  font-family: Muli-SemiBold;
  font-size: 1.6rem;
  height: 4.8rem;
  border: none;
  cursor: pointer;
  outline: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 0.3rem;
  }

  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `${MAIN_GREEN}`};
    color:  ${({ color }) =>
     color ? color : "white"};
 
  border-radius: 1.3rem;
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: white;
      color: ${MAIN_GREEN};
      border: 1px solid ${MAIN_GREEN};
    `}
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 0.1rem solid ${borderColor};
    `}
   ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

    ${(props) =>
    props.disabled &&
    css`
      background: #e8eaff 0% 0% no-repeat padding-box;
      color: #f6fefd;
      cursor: no-drop;
    `}

    ${(props) =>
    props.size === "big" &&
    css`
      height: 4.8rem;
    `}


    &:hover {
    box-shadow: 0px 70px 90px #0000000d;
  }
`;
export default CustomButton;
