import withSuspense from "components/HOC/WithSuspens";
import NewIndividual from "./Savings/NewIndividual";
import {
  HOME,
  SAVINGS,
  NEW_INDIVIDUAL_SAVINGS,
  NEW_GROUP_SAVINGS,
  NEW_TARGET_SAVINGS,
  TRANSACTION,
  ACCOUNT,
  INDIVIDUAL_SAVINGS,
  VIEW_INDIVIDUAL_SAVINGS,
  GROUP_SAVINGS,
  GROUP_TARGET,
  WITHDRAWAL,
  GROUP_INVITES,
  TRANSACTION_DOCUMENT,
  ACCEPTED_GROUP_INVITE,
} from "../pagesPath";
import NewGroupSavings from "./Savings/NewGroupSavings";
import SavingsPage from "./Savings";
import NewTargetSavings from "./Savings/NewTargetSavings";

export const protectedRoutes = [
  {
    path: HOME,
    component: withSuspense({ page: "AuthPages/Home" }),
    exact: false,
  },
  {
    path: TRANSACTION,
    component: withSuspense({ page: "AuthPages/Transaction" }),
    exact: false,
  },
  {
    path: TRANSACTION_DOCUMENT,
    component: withSuspense({ page: "AuthPages/TransactionDocument" }),
    exact: true,
  },
  {
    path: ACCOUNT,
    component: withSuspense({ page: "AuthPages/Account" }),
    exact: false,
  },

  {
    path: NEW_INDIVIDUAL_SAVINGS,
    component: NewIndividual,
    exact: true,
  },
  {
    path: NEW_GROUP_SAVINGS,
    component: NewGroupSavings,
    exact: true,
  },
  {
    path: NEW_TARGET_SAVINGS,
    component: NewTargetSavings,
    exact: true,
  },
  {
    path: SAVINGS,
    // component: withSuspense({ page: "AuthPages/Savings" }),
    component: SavingsPage,
    exact: true,
  },
  {
    path: INDIVIDUAL_SAVINGS,
    component: withSuspense({ page: "AuthPages/IndividualSavings" }),
    exact: true,
  },
  {
    path: GROUP_SAVINGS,
    component: withSuspense({ page: "AuthPages/GroupSavings" }),
    exact: true,
  },
  {
    path: GROUP_TARGET,
    component: withSuspense({ page: "AuthPages/GroupTarget" }),
    exact: true,
  },
  {
    path: `${GROUP_SAVINGS}/:id`,
    component: withSuspense({ page: "AuthPages/GroupSavingsPlanDetails" }),
    exact: true,
  },
  {
    path: `${GROUP_TARGET}/:id`,
    component: withSuspense({ page: "AuthPages/GroupTargetPlanDetails" }),
    exact: true,
  },

  {
    path: GROUP_INVITES,
    component: withSuspense({ page: "AuthPages/GroupInvites" }),
    exact: true,
  },
  {
    path: `${GROUP_INVITES}/:id`,
    component: withSuspense({ page: "AuthPages/GroupSingleInvite" }),
    exact: true,
  },
  {
    path: VIEW_INDIVIDUAL_SAVINGS,
    component: withSuspense({ page: "AuthPages/IndividualPlan" }),
    exact: false,
  },
  {
    path: WITHDRAWAL,
    component: withSuspense({ page: "AuthPages/Withdrawal" }),
    exact: false,
  },
  {
    path: ACCEPTED_GROUP_INVITE,
    component: withSuspense({ page: "AuthPages/AcceptedInvites" }),
    exact: true,
  },
  {
    path: `${ACCEPTED_GROUP_INVITE}/:id`,
    component: withSuspense({ page: "AuthPages/AcceptedGroupInvitesDetails" }),
    exact: false,
  },
];
