import React from "react";
import styled from "@emotion/styled";

const Spinner = () => {
  return <Spinner.Wrapper></Spinner.Wrapper>;
};

Spinner.Wrapper = styled.div`
  border: 0.2rem solid #e6ffea;
  border-top: 0.2rem solid #08cb7b;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default Spinner;
