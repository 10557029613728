import styled from "@emotion/styled";

export const Modal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #47486b80 0% 0% no-repeat padding-box;
  z-index: 90;
`;
