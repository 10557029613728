import React, { useEffect, useState } from "react";
import NewSavingTemplate, {
  CardType,
  FormStage,
  SavingDetails,
} from "components/templates/NewSavingTemplate";
import { ConfirmPlanModal } from "components/ConfirmPlan";
import { usePaystackPayment } from "react-paystack";
import { useSelector, useDispatch } from "react-redux";
import {
  initializeRequest,
  getCardsRequest,
  resetSaving,
  addCardRequest,
} from "redux/actions";
import { useHistory } from "react-router-dom";
import { IndividualSavings } from "components/ConfirmPlan/sharedType";
import { RootReducer } from "redux/store";
import moment from "moment";
import {
  GROUP_SAVINGS,
  GROUP_TARGET,
  PART_VIEW_INDIVIDUAL_SAVINGS,
} from "pages/pagesPath";
import { StageMapperValue } from "./SavingsTypes";
import { useNewSavingsPlan } from "./useNewSavingsPlan";
import { toast } from "react-toastify";

function calculateAmountToBePaid(values: IndividualSavings): string {
  const { startDate, frequency, endDate } = values;
  let difference = moment.duration(endDate?.diff(startDate)).asDays();

  if (frequency === "weekly") {
    difference = moment.duration(endDate?.diff(startDate)).asWeeks();
  } else if (frequency === "monthly") {
    difference = endDate?.diff(startDate, "months", true) as number;
  }

  const finalValue = values.target / Math.ceil(difference);

  return Math.ceil(finalValue).toString();
}

type NewSavingsContainerProps<T extends FormStage> = {
  formStagesMapper: Record<T, StageMapperValue>;
  onNextPageIsEmpty: (values: Record<string, any>) => void;
  savingDetails: SavingDetails;
  isCreateSuccessful: boolean;
  Id?: string;
};

export function NewSavingsContainer<StageType extends FormStage>(
  props: NewSavingsContainerProps<StageType>
) {
  const {
    formStagesMapper,
    onNextPageIsEmpty,
    savingDetails,
    isCreateSuccessful,
    Id,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = React.useState<IndividualSavings>({
    target: 0,
    hourOfDay: -1,
    dayOfWeek: -1,
  });
  const paymentState = useSelector((state: RootReducer) => state.payment);
  const newCardState = paymentState.newCard;
  const userDetails = useSelector((state: RootReducer) => state.auth.user.data);
  const [showDialogue, setshowDialogue] = useState(false);

  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState(0);
  const [updateAmount, setupdateAmount] = useState(false);

  const handleSubmitForm = () => {
    onNextPageIsEmpty(values);
  };

  const [currentStage, handlers] = useNewSavingsPlan(
    formStagesMapper,
    handleSubmitForm
  );

  useEffect(() => {
    dispatch(getCardsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      updateAmount &&
      !paymentState.paystack.processing &&
      paymentState.paystack.success
    ) {
      setReference(paymentState.paystack.data.reference);
      setAmount(paymentState.paystack.data.amount * 100);
      setshowDialogue(true);
      setupdateAmount(false);
    }
  }, [
    updateAmount,
    paymentState.paystack.data,
    paymentState.paystack.processing,
    paymentState.paystack.success,
  ]);

  useEffect(() => {
    if (newCardState.success) {
      handleValueChange({
        target: { name: "payment", value: newCardState.data.id },
      });
    }
  }, [newCardState.success, newCardState.data.id]);

  const config = {
    reference,
    amount,
    email: userDetails.email,
    publicKey: process.env.REACT_APP_PAY_STACK_KEY as string,
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    if (showDialogue) {
      toast.dismiss();
      initializePayment((data) => {
        dispatch(
          addCardRequest({
            reference: data.reference,
            channel: "paystack",
            ref: data.reference,
          })
        );
        toast.info("Adding Card...", { autoClose: false });
      });
      setshowDialogue(false);
    }
  }, [dispatch, initializePayment, showDialogue]);

  const handleValueChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    if (name === "target") {
      newValue = +value;

      if (Number.isNaN(newValue)) return;
    }

    const recalculateAmountWhen = new Set([
      "startDate",
      "endDate",
      "frequency",
    ]);

    setValues((prevValue) => {
      const newValues = {
        ...prevValue,
        [name]: newValue,
      };

      if (recalculateAmountWhen.has(name)) {
        newValues["routineAmount"] = calculateAmountToBePaid(newValues);
      }

      return newValues;
    });
  };

  const handleAddCard = () => {
    dispatch(initializeRequest());
    setupdateAmount(true);
    toast.info("processing Please wait....", { autoClose: false });
  };

  return (
    <>
      <NewSavingTemplate
        cards={paymentState.cards.data as CardType[]}
        values={values}
        savingDetails={savingDetails}
        onValueChange={handleValueChange}
        currentStage={currentStage}
        onContinue={handlers.handleContinue}
        onAddCard={handleAddCard}
        onBack={handlers.handleBack}
        createSuccessfully={isCreateSuccessful}
        onSuccess={() => {
          dispatch(resetSaving());
          savingDetails.savingType === "Individual"
            ? history.push(PART_VIEW_INDIVIDUAL_SAVINGS + `/${Id}`)
            : savingDetails.savingType === "Group"
            ? history.push(`${GROUP_SAVINGS}/${Id}`)
            : history.push(`${GROUP_TARGET}/${Id}`);
        }}
      />

      {currentStage === "confirmModal" && (
        <ConfirmPlanModal
          onConfirmClick={handlers.handleContinue}
          onBackClick={handlers.handleBack}
          values={values}
          type={savingDetails.savingType}
        />
      )}
    </>
  );
}
