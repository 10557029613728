import React, { useEffect } from "react";
import SavingsInfoLayout, {
  NewSavingsLayout,
} from "components/templates/SavingsLayout";

import { RouteComponentProps } from "react-router-dom";
import {
  GROUP_SAVINGS,
  GROUP_TARGET,
  INDIVIDUAL_SAVINGS,
  NEW_SAVINGS,
} from "pages/pagesPath";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "redux/store";
import {
  getAllAcceptedGroup,
  getAllAcceptedTarget,
  getGroupSavings,
  getSavingRequest,
  getTargetSavings,
} from "redux/actions";

function Savings(props: RouteComponentProps) {
  const { history } = props;
  const [showNewPage, setShowNewPage] = React.useState(false);
  const auth = useSelector((state: RootReducer) => state.auth.user.data);
  const userSavings = useSelector(
    (state: RootReducer) => state.saving.individualSavings.data
  );
  const userSavingsProcessing = useSelector(
    (state: RootReducer) => state.saving.individualSavings.processing
  );
  const groupSavings = useSelector(
    (state: RootReducer) => state.saving.groupSavings.data
  );
  const groupTarget = useSelector(
    (state: RootReducer) => state.saving.groupTarget.data
  );
  const groupSavingsProcessing = useSelector(
    (state: RootReducer) => state.saving.groupSavings.processing
  );
  const groupTargetProcessing = useSelector(
    (state: RootReducer) => state.saving.groupTarget.processing
  );
  const allAcceptedInviteProcessing = useSelector(
    (state: RootReducer) => state.saving.allAcceptedInvite.processing
  );
  const allAcceptedTargetProcessing = useSelector(
    (state: RootReducer) => state.saving.allAcceptedTarget.processing
  );

  // let activeCount = 0;
  let total = 0;

  userSavings.map((saving) => {
    const { target_amount, saving_cycle_histories } = saving;
    let currentTotal = 0;

    saving_cycle_histories.forEach((item) => {
      currentTotal += Number(item.amount);
    });

    if (target_amount === currentTotal.toString()) {
      total += 1;
    }

    return total;
  });

  // userSavings.forEach(saving => { Commented this out for now because inactive plans are showing as active from the backend
  //   if (saving.status === 'active') {
  //     activeCount += 1;
  //   }
  // });
  const IndividualSavingDetails = {
    total: `${userSavings.length} plan(s)`,
    active: userSavings.length - total,
  };
  const GroupSavingDetails = {
    total: `${groupSavings.length} plan(s)`,
    active: 0,
  };
  const GroupTargetDetails = {
    total: `${groupTarget.length} plans(s)`,
    active: 0,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSavingRequest());
    dispatch(getGroupSavings());
    dispatch(getAllAcceptedGroup());
    dispatch(getTargetSavings());
    dispatch(getAllAcceptedTarget());
  }, [dispatch]);

  const togglePageView = () => setShowNewPage(!showNewPage);

  const navigateToNewPage = (cardType: "individual" | "group" | "target") => {
    history.push(`${NEW_SAVINGS}/${cardType}`);
  };

  const onClickIndividual = () => {
    history.push(INDIVIDUAL_SAVINGS);
  };

  const onClickGroup = () => {
    history.push(GROUP_SAVINGS);
  };

  const onClickTarget = () => {
    history.push(GROUP_TARGET);
  };

  return showNewPage ? (
    <NewSavingsLayout
      onCardClick={navigateToNewPage}
      onBackClick={togglePageView}
    />
  ) : (
    <SavingsInfoLayout
      onSaveNowClick={togglePageView}
      onClickIndividual={onClickIndividual}
      onClickGroup={onClickGroup}
      onClickTarget={onClickTarget}
      userBalance={auth?.wallet?.balance}
      IndividualSavingDetails={IndividualSavingDetails}
      IndividualSavingProcessing={userSavingsProcessing}
      GroupSavingDetails={GroupSavingDetails}
      GroupTargetDetails={GroupTargetDetails}
      GroupSavingProcessing={groupSavingsProcessing}
      GroupTargetProcessing={groupTargetProcessing}
      allAcceptedInviteProcessing={allAcceptedInviteProcessing}
      allAcceptedTargetProcessing={allAcceptedTargetProcessing}
    />
  );
}

export default Savings;
