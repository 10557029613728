import {
  defaultSingleObjectArrayState,
  defaultSingleObjectState,
} from "utils/constants";
import { extractStatus, handleFetch } from "helpers";
import * as types from "redux/types";
import {
  GET_PAYOUT_STATUS_ERROR,
  GET_PAYOUT_STATUS_REQUEST,
  GET_PAYOUT_STATUS_SUCCESS,
  GET_SINGLE_ACCEPTED_GROUP_ERROR,
  GET_SINGLE_ACCEPTED_GROUP_REQUEST,
  GET_SINGLE_ACCEPTED_GROUP_SUCCESS,
  GET_SINGLE_ACCEPTED_TARGET_ERROR,
  GET_SINGLE_ACCEPTED_TARGET_REQUEST,
  GET_SINGLE_ACCEPTED_TARGET_SUCCESS,
} from "redux/types";

const defaultPayload = {
  data: [],
  errors: [],
};
const initalState = {
  individualSavings: defaultSingleObjectArrayState,
  groupSavings: defaultSingleObjectArrayState,
  groupTarget: defaultSingleObjectArrayState,
  create: defaultSingleObjectState,
  createGroupSaving: defaultSingleObjectState,
  createGroupTarget: defaultSingleObjectState,

  singleIndividual: {
    processing: true,
    data: {},
  },
  singleGroup: {
    processing: true,
    data: {},
  },
  singleTarget: {
    processing: true,
    data: {},
  },
  inviteGroupMember: defaultSingleObjectState,
  groupInvites: defaultSingleObjectState,
  targetInvites: defaultSingleObjectState,
  acceptOrDecline: defaultSingleObjectState,
  onAccept: "",
  singleAcceptedTarget: defaultSingleObjectArrayState,
  singleAcceptedInvite: defaultSingleObjectArrayState,
  allAcceptedInvite: defaultSingleObjectArrayState,
  allAcceptedTarget: defaultSingleObjectArrayState,
  payOutStatus: defaultSingleObjectArrayState,
};

const savingReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);

  switch (type) {
    case types.CREATE_INDIVIDUAL_SAVING_REQUEST:
    case types.CREATE_INDIVIDUAL_SAVING_ERROR:
      return handleFetch(state, status, payload, "create");
    case types.CREATE_INDIVIDUAL_SAVING_SUCCESS:
      return {
        ...state,
        individualSavings: {
          ...state.individualSavings,
          data: [],
        },
        create: {
          ...state.create,
          data: { ...payload.data },
          success: true,
        },
      };
    case types.GET_SAVINGS_REQUEST:
    case types.GET_SAVINGS_SUCCESS:
    case types.GET_SAVINGS_ERROR:
      return handleFetch(state, status, payload, "individualSavings");
    case types.GET_GROUP_SAVING_REQUEST:
    case types.GET_GROUP_SAVING_SUCCESS:
    case types.GET_GROUP_SAVING_ERROR:
      return handleFetch(state, status, payload, "groupSavings");
    case types.GET_GROUP_TARGET_REQUEST:
    case types.GET_GROUP_TARGET_SUCCESS:
    case types.GET_GROUP_TARGET_ERROR:
      return handleFetch(state, status, payload, "groupTarget");
    case types.CREATE_GROUP_SAVING_REQUEST:
    case types.CREATE_GROUP_SAVING_ERROR:
      return handleFetch(state, status, payload, "createGroupSaving");
    case types.CREATE_GROUP_TARGET_REQUEST:
    case types.CREATE_GROUP_TARGET_ERROR:
      return handleFetch(state, status, payload, "createGroupTarget");
    case types.CREATE_GROUP_TARGET_SUCCESS:
      return {
        ...state,
        groupTarget: {
          ...state.groupTarget,
          data: [],
        },
        createGroupTarget: {
          ...state.createGroupTarget,
          data: { ...payload.data },
          success: true,
        },
      };
    case types.CREATE_GROUP_SAVING_SUCCESS:
      return {
        ...state,
        groupSavings: {
          ...state.groupSavings,
          data: [],
        },
        createGroupSaving: {
          ...state.createGroupSaving,
          data: { ...payload.data },
          success: true,
        },
      };
    case types.GET_SINGLE_SAVING:
      return {
        ...state,
        singleIndividual: {
          processing: false,
          data: state.individualSavings.data.find(
            (saving) => saving.id === payload
          ),
        },
      };
    case types.GET_SINGLE_GROUP_SAVING:
      return {
        ...state,
        singleGroup: {
          processing: false,
          data: state.groupSavings.data.find((saving) => saving.id === payload),
        },
      };
    case types.GET_SINGLE_TARGET_SAVING:
      return {
        ...state,
        singleTarget: {
          processing: false,
          data: state.groupTarget.data.find((saving) => saving.id === payload),
        },
      };

    case types.RESET_SAVING_PLAN:
      return {
        ...state,
        create: defaultSingleObjectState,
        createGroupSaving: defaultSingleObjectState,
      };
    case types.INVITE_GROUP_MEMBERS_REQUEST:
    case types.INVITE_GROUP_MEMBERS_ERROR:
      return handleFetch(state, status, payload, "inviteGroupMember");
    case types.INVITE_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        groupSavings: {
          ...state.groupSavings,
          data: [],
        },
      };
    case types.VIEW_GROUP_INVITE_REQUEST:
    case types.VIEW_GROUP_INVITE_SUCCESS:
    case types.VIEW_GROUP_INVITE_ERROR:
      return handleFetch(state, status, payload, "groupInvites");
    case types.VIEW_TARGET_INVITE_REQUEST:
    case types.VIEW_TARGET_INVITE_SUCCESS:
    case types.VIEW_TARGET_INVITE_ERROR:
      return handleFetch(state, status, payload, "targetInvites");
    case types.ACCEPT_OR_DECLINE_GROUP_INVITE_ERROR:
    case types.ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST:
      return handleFetch(state, status, payload, "acceptOrDecline");
    case types.ACCEPT_OR_DECLINE_GROUP_INVITE_SUCCESS:
      return {
        ...state,
        onAccept: payload.status,
      };
    case GET_SINGLE_ACCEPTED_GROUP_REQUEST:
    case GET_SINGLE_ACCEPTED_GROUP_SUCCESS:
    case GET_SINGLE_ACCEPTED_GROUP_ERROR:
      return handleFetch(state, status, payload, "singleAcceptedInvite");
    case GET_SINGLE_ACCEPTED_TARGET_REQUEST:
    case GET_SINGLE_ACCEPTED_TARGET_SUCCESS:
    case GET_SINGLE_ACCEPTED_TARGET_ERROR:
      return handleFetch(state, status, payload, "singleAcceptedTarget");
    case types.GET_ALL_ACCEPTED_GROUP.request:
    case types.GET_ALL_ACCEPTED_GROUP.success:
    case types.GET_ALL_ACCEPTED_GROUP.error:
      return handleFetch(state, status, payload, "allAcceptedInvite");
    case types.GET_ALL_ACCEPTED_TARGET.request:
    case types.GET_ALL_ACCEPTED_TARGET.success:
    case types.GET_ALL_ACCEPTED_TARGET.error:
      return handleFetch(state, status, payload, "allAcceptedTarget");

    case GET_PAYOUT_STATUS_REQUEST:
    case GET_PAYOUT_STATUS_SUCCESS:
    case GET_PAYOUT_STATUS_ERROR:
      return handleFetch(state, status, payload, "payOutStatus");
    default:
      return state;
  }
};

export default savingReducer;
