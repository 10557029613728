import * as types from "redux/types";
import {
  GET_SAVINGS_REQUEST,
  RESET_SAVING_PLAN,
  GET_GROUP_SAVING_REQUEST,
  GET_GROUP_TARGET_REQUEST,
  GET_SINGLE_SAVING,
  CREATE_GROUP_SAVING_REQUEST,
  CREATE_GROUP_TARGET_REQUEST,
  CREATE_INDIVIDUAL_SAVING_REQUEST,
  GET_SINGLE_GROUP_SAVING,
  INVITE_GROUP_MEMBERS_REQUEST,
  VIEW_GROUP_INVITE_REQUEST,
  ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST,
  GET_SINGLE_ACCEPTED_GROUP_REQUEST,
  GET_PAYOUT_STATUS_REQUEST,
  GET_SINGLE_TARGET_SAVING,
  INVITE_TARGET_MEMBERS_REQUEST,
  ACCEPT_OR_DECLINE_TARGET_INVITE_REQUEST, VIEW_TARGET_INVITE_REQUEST,
} from "redux/types";

export const getSavingRequest = () => ({
  type: GET_SAVINGS_REQUEST,
});

export const getSingleSaving = (payload) => ({
  type: GET_SINGLE_SAVING,
  payload,
});
export const createIndividualSaving = (payload) => ({
  type: CREATE_INDIVIDUAL_SAVING_REQUEST,
  payload,
});
export const resetSaving = () => ({
  type: RESET_SAVING_PLAN,
});

export const getGroupSavings = () => ({
  type: GET_GROUP_SAVING_REQUEST,
});

export const getTargetSavings = () => ({
  type: GET_GROUP_TARGET_REQUEST,
});


export const createGroupSaving = (payload) => ({
  type: CREATE_GROUP_SAVING_REQUEST,
  payload,
});
export const createGroupTarget = (payload) => ({
  type: CREATE_GROUP_TARGET_REQUEST,
  payload,
});


export const getSingleTarget = (payload) => ({
type: GET_SINGLE_TARGET_SAVING,
payload,
})
export const getSingleGroup = (payload) => ({
  type: GET_SINGLE_GROUP_SAVING,
  payload,
});

export const getSingleAcceptedGroup = (payload) => ({
  type: GET_SINGLE_ACCEPTED_GROUP_REQUEST,
  payload,
});

export const getSingleAcceptedTarget = (payload) => ({
  type: GET_SINGLE_ACCEPTED_GROUP_REQUEST,
  payload,
})

export const getAllAcceptedGroup = () => ({
  type: types.GET_ALL_ACCEPTED_GROUP.request,
});
export const getAllAcceptedTarget = () => ({
  type: types.GET_ALL_ACCEPTED_TARGET.request,
})

export const inviteTargetMembers = (payload) => ({
  type: INVITE_TARGET_MEMBERS_REQUEST,
  payload,
})

export const inviteGroupMembers = (payload) => ({
  type: INVITE_GROUP_MEMBERS_REQUEST,
  payload,
});

export const getAllInvites = () => ({
  type: VIEW_GROUP_INVITE_REQUEST,
});
export const getAllTarget = () => ({
  type: VIEW_TARGET_INVITE_REQUEST
})

export const getPayoutStatus = (payload) => ({
  type: GET_PAYOUT_STATUS_REQUEST,
  payload,
});

export const acceptOrDeclineGroupInvite = (payload) => ({
  type: ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST,
  payload,
});

export const acceptOrDeclineTargetInvite = ( payload ) => ({
  type: ACCEPT_OR_DECLINE_TARGET_INVITE_REQUEST,
  payload
})