import { createActionType } from "helpers/reducerHelper";

export const CREATE_INDIVIDUAL_SAVING_REQUEST = "CREATE_INDIVIDUAL_SAVING_REQUEST";
export const CREATE_INDIVIDUAL_SAVING_SUCCESS = "CREATE_INDIVIDUAL_SAVING_SUCCESS";
export const CREATE_INDIVIDUAL_SAVING_ERROR = "CREATE_INDIVIDUAL_SAVING_ERROR";

export const GET_SAVINGS_REQUEST = "GET_SAVINGS_REQUEST";
export const GET_SAVINGS_SUCCESS = "GET_SAVINGS_SUCCESS";
export const GET_SAVINGS_ERROR = "GET_SAVINGS_ERROR";

export const GET_SINGLE_SAVING = "GET_SINGLE_SAVING";
export const GET_SINGLE_GROUP_SAVING = "GET_SINGLE_GROUP_SAVING";
export const GET_SINGLE_TARGET_SAVING = "GET_SINGLE_TARGET_SAVING";

export const RESET_SAVING_PLAN = "RESET_SAVING_PLAN";

export const CREATE_GROUP_SAVING_REQUEST = "CREATE_GROUP_SAVING_REQUEST";
export const CREATE_GROUP_SAVING_SUCCESS = "CREATE_GROUP_SAVING_SUCCESS";
export const CREATE_GROUP_SAVING_ERROR = "CREATE_GROUP_SAVING_ERROR";

export const CREATE_GROUP_TARGET_REQUEST = "CREATE_GROUP_TARGET_REQUEST";
export const CREATE_GROUP_TARGET_SUCCESS = "CREATE_GROUP_TARGET_SUCCESS";
export const CREATE_GROUP_TARGET_ERROR = "CREATE_GROUP_TARGET_ERROR";

export const GET_GROUP_TARGET_REQUEST = "GET_GROUP_TARGET_REQUEST";
export const GET_GROUP_TARGET_SUCCESS = "GET_GROUP_TARGET_SUCCESS";
export const GET_GROUP_TARGET_ERROR = "GET_GROUP_TARGET_ERROR";

export const GET_GROUP_SAVING_REQUEST = "GET_GROUP_SAVING_REQUEST";
export const GET_GROUP_SAVING_SUCCESS = "GET_GROUP_SAVING_SUCCESS";
export const GET_GROUP_SAVING_ERROR = "GET_GROUP_SAVING_ERROR";

export const INVITE_GROUP_MEMBERS_REQUEST = "INVITE_GROUP_MEMBERS_REQUEST";
export const INVITE_GROUP_MEMBERS_SUCCESS = "INVITE_GROUP_MEMBERS_SUCCESS";
export const INVITE_GROUP_MEMBERS_ERROR = "INVITE_GROUP_MEMBERS_ERROR";


export const INVITE_TARGET_MEMBERS_REQUEST = "INVITE_TARGET_MEMBERS_REQUEST";
export const INVITE_TARGET_MEMBERS_SUCCESS = "INVITE_TARGET_MEMBERS_SUCCESS";
export const INVITE_TARGET_MEMBERS_ERROR = "INVITE_TARGET_MEMBERS_ERROR";

export const VIEW_GROUP_INVITE_REQUEST = "VIEW_GROUP_INVITE_REQUEST";
export const VIEW_GROUP_INVITE_SUCCESS = "VIEW_GROUP__INVITE_SUCCESS";
export const VIEW_GROUP_INVITE_ERROR = "VIEW_GROUP_INVITE_ERROR";


export const VIEW_TARGET_INVITE_REQUEST = "VIEW_TARGET_INVITE_REQUEST";
export const VIEW_TARGET_INVITE_SUCCESS = "VIEW_TARGET_INVITE_SUCCESS";
export const VIEW_TARGET_INVITE_ERROR = "VIEW_TARGET_INVITE_ERROR";

export const ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST = "ACCEPT_OR_DECLINE_GROUP_INVITE_REQUEST"
export const ACCEPT_OR_DECLINE_GROUP_INVITE_SUCCESS = "ACCEPT_OR_DECLINE_GROUP_INVITE_SUCCESS"
export const ACCEPT_OR_DECLINE_GROUP_INVITE_ERROR = "ACCEPT_OR_DECLINE_GROUP_INVITE_ERROR"

export const ACCEPT_OR_DECLINE_TARGET_INVITE_REQUEST = "ACCEPT_OR_DECLINE_TARGET_INVITE_REQUEST"
export const ACCEPT_OR_DECLINE_TARGET_INVITE_SUCCESS = "ACCEPT_OR_DECLINE_TARGET_INVITE_SUCCESS"
export const ACCEPT_OR_DECLINE_TARGET_INVITE_ERROR = "ACCEPT_OR_DECLINE_TARGET_INVITE_ERROR"

export const GET_SINGLE_ACCEPTED_GROUP_REQUEST = "GET_SINGLE_ACCEPTED_GROUP_REQUEST"
export const GET_SINGLE_ACCEPTED_GROUP_SUCCESS = "GET_SINGLE_ACCEPTED_GROUP_SUCCESS"
export const GET_SINGLE_ACCEPTED_GROUP_ERROR = "GET_SINGLE_ACCEPTED_GROUP_ERROR"

export const GET_SINGLE_ACCEPTED_TARGET_REQUEST = "GET_SINGLE_ACCEPTED_TARGET_REQUEST"
export const GET_SINGLE_ACCEPTED_TARGET_SUCCESS = "GET_SINGLE_ACCEPTED_TARGET_SUCCESS"
export const GET_SINGLE_ACCEPTED_TARGET_ERROR = "GET_SINGLE_ACCEPTED_TARGET_ERROR"

export const GET_PAYOUT_STATUS_REQUEST = "GET_PAYOUT_STATUS_REQUEST"
export const GET_PAYOUT_STATUS_SUCCESS = "GET_PAYOUT_STATUS_SUCCESS"
export const GET_PAYOUT_STATUS_ERROR = "GET_PAYOUT_STATUS_ERROR"

export const GET_ALL_ACCEPTED_GROUP = createActionType("GET_ALL_ACCEPTED_GROUP")
export const GET_ALL_ACCEPTED_TARGET = createActionType("GET_ALL_ACCEPTED_TARGET")