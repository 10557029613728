import styled from "@emotion/styled";

export const AuthCard = styled.div`
  padding: 2rem 2rem;
  margin: 2rem auto;
  background-color: #fff;
  width: 33%;
  border-radius: 1rem;
  box-shadow: 0px 3rem 5rem #05915729;
  .main {
    padding: 0 3rem;
    padding-bottom: 2rem;
  }
  @media (max-width: 1179px) {
    /* For tablet: */
    width: 45%;
    margin: 0 auto;
    .main {
      padding: 0 0.3rem;
    }
    .primary-heading {
      width: 100%;
    }
  }

  @media (max-width: 800px) /* For mobile phones: */ {
    width: 50%;
    padding: 1.2rem;

    .main {
      padding: 0 0.3rem;
    }
    .primary-heading {
      width: 100%;
    }
  }
  @media (max-width: 580px) {
    /* For mobile phones: */
    width: 90%;
    padding: 1.2rem;

    .main {
      padding: 0 0.3rem;
    }
    .primary-heading {
      width: 100%;
    }
  }
`;

export default AuthCard;
