import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "components/atoms/CustomButton";
import clappingHand from "assets/icons/176-waving-hand.svg";
import { Link, useHistory } from "react-router-dom";
import Input from "components/Input";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "components/Spinner";
import { EMAIL_SENT, LOGIN,TERMS, VERIFY_USER_TOKEN } from "../pagesPath";
import { signUpRequest } from "redux/actions";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { RootReducer } from 'redux/store';
import { toast } from "react-toastify";


type SignUpDetails = {
  name?: string;
  lname?: string;
  email?: string;
  phone?: string;
  phone_country?: string;
  password?: string;
  confirmPassword? : string;
  termsAndCondition?: boolean;
  callback_url?: string;
};

const Signup = () => {
  const { success, processing, errors } = useSelector(
    (state: RootReducer) => state.auth.user
  );
  const validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{4,15}$/


  const history = useHistory();
  const dispatch = useDispatch();
    const [visible1, setVisible1] = useState(false)
    const [visible2, setVisible2] = useState(false)

  useEffect(() => {
    if (success) history.push(EMAIL_SENT);
  }, [success, history, errors]);

  const [formData, setFormData] = useState<SignUpDetails>({
    callback_url: `${window.location.origin}${VERIFY_USER_TOKEN}`
  });

  const [tandc, setTandc] = useState(false);

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [country, setcountry] = useState();

  const registerUser = (e: any) => {
       e.preventDefault();

if(!formData.termsAndCondition){
  toast.dismiss()
  toast.error("You have read and agreed to the terms and conditions")
  return;
}

    if(formData.password !== formData.confirmPassword){
      toast.dismiss()
      toast.error("Passwords mismatch");
      return
    }
    else if(formData.password && !formData.password.match(validatePassword)){
      toast.dismiss()
      toast.error("Passwords must include special characters, uppercase, lowercase and numbers");
     return
    }

    dispatch(
      signUpRequest({
        ...formData,
        phone_country: country ? country : "NG",
      })
    );
    
  };

  

  return (
    <Signup.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="primary-heading">
            <img src={clappingHand} alt="hand" className="hand" />
            Hey <span className="secondary-heading">there</span>
            <div className="sm-heading">
              Create an account let us learn a little about you
            </div>
          </div>

          <form onSubmit={registerUser}>
            <div className="name">

              <Input
                placeholder="First Name"
                name="name"
                type="text"
                required={true}
                onChange={handleChange}
                value={formData.name}
              />
          
              <Input
                placeholder="Last Name"
                name="lname"
                type="text"
                value={formData.lname}
                required={true}
                onChange={handleChange}
              />
            </div>

            <PhoneInput
              international
              defaultCountry="NG"
              value={formData.phone}
              onChange={(phone) =>
                handleChange({
                  target: { name: "phone", value: phone },
                })
              }
              onCountryChange={setcountry}
            />

            <Input
              placeholder="Email Address"
              name="email"
              type="email"
              required={true}
              onChange={handleChange}
              value={formData.email}
            />
            <div className="pass-warp">
              <div className="pass">
                <Input
                placeholder="Password"
                name="password"
                type={visible1 ? "text" : "password"}
                required
                value={formData.password}
                onChange={handleChange}
              />
              {
              visible1 ?
              <div onClick={() => setVisible1(!visible1)}>
                  <i className="togglePassword fa fa-eye" id="togglePassword"></i>  
              </div> : 
               <div onClick={() => setVisible1(!visible1)}>
                  <i className="togglePassword fa fa-eye-slashed" id="togglePassword"></i>
               </div>
                }
              </div>
       
                <div className="pass">
                <Input
                placeholder="Confirm Password"
                name="confirmPassword"
                type={visible2 ? "text" : "password"}
                required
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              {
              visible2 ?
              <div onClick={() => setVisible2(!visible2)}>
                  <i className="togglePassword fa fa-eye" id="togglePassword"></i>  
              </div> : 
               <div onClick={() => setVisible2(!visible2)}>
                  <i className="togglePassword fa fa-eye-slashed" id="togglePassword"></i>
               </div>
              }
                </div>
              <div className="terms">
                <input name={"termsAndCondition"}  type={"checkbox"} checked={tandc} onClick={handleChange} onChange={() => {setTandc(!tandc)}}/>
                {" "}<Link to={TERMS} className={"tandc"} >Terms and conditions</Link>


              </div>
          
            </div>
            <div className="submission">
              <div className="captcha"></div>
              <Button>{processing ? <Spinner /> : "Sign Up"}</Button>
            </div>
          </form>
        </div>
      </AuthCard>
      <div className="footer">
        Already have an account?{" "}
        <Link to={LOGIN} className="footer-primary">
          Sign In
        </Link>
      </div>
    </Signup.Wrapper>
  );
};

Signup.Wrapper = styled.div`
  .pass-warp {
    padding-bottom: 2rem;
    .pass{
      position: relative;
    }
  }
  .terms{
  width: 60%;
  margin:  auto;
  text-align: center;
    input[type=checkbox]{
    
    }
  }
  .main {
    padding: 0 3rem;
    .primary-heading {
      width: 80%;
      color: #059157;
      font-size: 2rem;
      font-weight: bold;
      margin: 2rem auto;
      text-align: center;

      .secondary-heading {
        color: #47486b;
        font-style: italic;
      }
      img {
        height: 2.5rem;
      }
    }
    .sm-heading {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      color: #080b4d;
      opacity: 0.6;
    }
    .name {
      display: flex;
      justify-content: space-between;
      input {
        width: 98%;
        margin-right: auto;
      }
    }
    .forgot-password {
      color: #08cb7b;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 2rem 0;
      text-decoration: none;
    }
    .submission {
      display: flex;
      justify-content: space-between;
      height: 4rem;

      .captcha {
        width: 3rem;
      }
    }
    .PhoneInput {
      margin-top: 2rem;
      .PhoneInputCountry {
        font-size: 1.6rem;
      }
      .PhoneInputInput {
        font-size: 1.6rem;
        outline: none;
        font-family: Muli-Bold;
        color: #059157;
        padding: 1rem;
        padding-left: 2rem;
        border-radius: 1rem;
        border: 1px solid #bec6df;
        width: 100%;
        display: block;
        transition: all 0.3s;
        box-shadow: 0px 3px 6px #0000000d;
      }
    }
  }
  .footer {
    width: 100%;
    text-align: center;
    margin: 2rem auto;
    font-size: 1.2rem;
    .footer-primary {
      font-weight: bold;
      color: #08cb7b;
      text-decoration: none;
    }
  }
  .fa-eye:before {
    content: "\f06e";
    position: absolute;
    right: 4%;
    top: 30%;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .fa-eye-slashed:before{
    content: "\f070";
    position: absolute;
    right: 4%;
    top: 30%;
    font-size: 1.4rem;
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    margin-top: 3rem;
  }
`;
export default Signup;
