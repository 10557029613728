import React from "react";
import styled from "@emotion/styled";
import CustomButton from "components/atoms/CustomButton";
import {
  IndividualSavingCard,
  GroupSavingCard,
  GroupTargetSavingCard,
  GroupSelectSavingType,
  IndividualSelectSavingType,
  GroupTargetSelectSavingType,
} from "components/cards/SavingTypeInfo";
import BackButton from "components/BackButton";
import { Heading } from "components/typographpy/Heading";
// import { NotificationHeading } from "components/NotificationHeading";
// import Notification from "components/Notification";
import { MySavingsIcon } from "components/Icon";
import { formatNumber } from "utils/constants";
import LoadingSkeletonCard from "components/atoms/LoadingSkeletonCard";

const SavingsWrapper = styled.div`
  font-size: 2rem;
  min-height: 100vh;
  padding-right: 32.448%;
  padding-top: 20px;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
`;

type SavingDetails = {
  total: string;
  active: number;
};
interface SavingsInfoLayoutProps {
  onSaveNowClick: () => void;
  onClickIndividual: () => void;
  onClickGroup: () => void;
  onClickTarget: () => void;
  userBalance: string;
  IndividualSavingDetails: SavingDetails;
  GroupSavingDetails: SavingDetails;
  GroupTargetDetails: SavingDetails;
  IndividualSavingProcessing: boolean;
  GroupSavingProcessing: boolean;
  GroupTargetProcessing: boolean;
  allAcceptedInviteProcessing: boolean;
  allAcceptedTargetProcessing: boolean;
}

function SavingsInfoLayout(props: SavingsInfoLayoutProps) {
  const {
    onSaveNowClick,
    onClickIndividual,
    onClickGroup,
    onClickTarget,
    userBalance,
    GroupSavingDetails,
    GroupTargetDetails,
    IndividualSavingDetails,
    IndividualSavingProcessing,
    GroupSavingProcessing,
    GroupTargetProcessing,
    allAcceptedInviteProcessing,
    allAcceptedTargetProcessing,
  } = props;
  const balance = formatNumber(userBalance);
  const mainBalance = balance.split(".")[0];
  const koboBalance = balance.split(".")[1];

  return (
    <SavingsWrapper>
      {/* <NotificationHeading /> */}

      <SavingsInfoLayout.Wrapper>
        <div className="header-wrapper">
          <Heading type="h3"> Savings</Heading>
        </div>

        <section className="summary">
          <div>
            <div>
              <h5>Total Saving</h5>

              <div className="amount">
                <div className="currency">₦</div>
                <div className="whole"> {mainBalance} </div>
                <div className="kobo">.{koboBalance}</div>
              </div>
            </div>

            <div className="button-group">
              <CustomButton size="big" onClick={onSaveNowClick}>
                Save Now
                <MySavingsIcon />
              </CustomButton>
            </div>
          </div>
        </section>

        <section className="breakdown">
          {IndividualSavingProcessing ? (
            <LoadingSkeletonCard />
          ) : (
            <IndividualSavingCard
              numberOfActive={IndividualSavingDetails.active}
              typeInfo={IndividualSavingDetails.total}
              onClick={onClickIndividual}
            />
          )}
          {GroupTargetProcessing || allAcceptedTargetProcessing ? (
            <LoadingSkeletonCard />
          ) : (
            <GroupTargetSavingCard
              numberOfActive={GroupTargetDetails.active}
              typeInfo={GroupTargetDetails.total}
              onClick={onClickTarget}
            />
          )}
        </section>
        <section className="breakdown">
          {GroupSavingProcessing || allAcceptedInviteProcessing ? (
            <LoadingSkeletonCard />
          ) : (
            <GroupSavingCard
              numberOfActive={GroupSavingDetails.active}
              typeInfo={GroupSavingDetails.total}
              onClick={onClickGroup}
            />
          )}
        </section>
        <aside></aside>
      </SavingsInfoLayout.Wrapper>
    </SavingsWrapper>
  );
}

SavingsInfoLayout.Wrapper = styled.div`
  .header-wrapper {
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
  }
  section.summary {
    height: 175px;

    border-radius: 20px;
    background: #51459e 0% 0% no-repeat padding-box;

    color: white;
    margin-bottom: 3.2rem;
    > div {
      background: transparent url("/flower.svg") 0% 0% no-repeat padding-box;
      padding: 43px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h5 {
      font-size: 1.6rem;
      margin-bottom: 3.3rem;
      font-family: Muli-SemiBold;
      opacity: 0.9;
    }

    .amount {
      display: flex;
      align-items: center;
      .currency {
        font: normal normal 800 2rem Proxima Nova;
        margin-right: 5px;
      }
      .kobo {
        opacity: 0.8;
      }
    }
  }

  section.breakdown {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 960px) {
    section.summary {
      height: 205px;
    }
    .breakdown {
      flex-direction: column;
      align-items: center;
      > * {
        margin: 2rem auto;
        width: 100%;
      }
    }
    section.summary > div {
      flex-direction: column;
      padding: 3rem;
      align-items: flex-start;
      div {
        padding-bottom: 0.5rem;
      }
    }
  }
`;

type NewSavingsLayoutProps = {
  onBackClick: () => void;
  onCardClick: (cardType: "individual" | "group" | "target") => void;
};

export function NewSavingsLayout(props: NewSavingsLayoutProps) {
  const { onBackClick, onCardClick } = props;

  return (
    <SavingsWrapper>
      {/* <Notification
        text=" You have two new group savings invitations."
        action="View all"
      /> */}
      <NewSavingsLayout.Wrapper>
        <BackButton onClick={onBackClick} />
        <div className="heading-wrapper">
          <Heading type="h3">Select savings type</Heading>
        </div>
        <div className="types">
          <IndividualSelectSavingType
            onClick={() => onCardClick("individual")}
          />
          <GroupTargetSelectSavingType onClick={() => onCardClick("target")} />

          <GroupSelectSavingType onClick={() => onCardClick("group")} />
        </div>
      </NewSavingsLayout.Wrapper>
    </SavingsWrapper>
  );
}

NewSavingsLayout.Wrapper = styled.div`
  .heading-wrapper {
    margin-bottom: 2.2rem;
    margin-top: 3.3rem;
  }

  .types {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 960px) {
    .types {
      flex-direction: column;
      div {
        margin-bottom: 1.5rem;
      }
    }
  }
`;

export default SavingsInfoLayout;
