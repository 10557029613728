import React, { useState } from "react";
import styled from "@emotion/styled";
import AuthCard from "components/cards/AuthCard";
import AuthBackground from "components/AuthBackground";
import Button from "components/atoms/CustomButton";
import AuthBrand from "components/AuthBrand";
import Input from "components/Input";
import { Link } from "react-router-dom";
import { ENTER_NEW_PASSWORD, LOGIN } from "../pagesPath";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "redux/actions";
import { toast } from "react-toastify";
import { RootReducer } from "redux/store";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const forgotPassword = useSelector(
    (state: RootReducer) => state.auth.forgotPassword
  );

  const handleReset = () => {
    if (email) {
      dispatch(
        forgotPasswordRequest({
          email,
          callback_url: `${window.location.origin}${ENTER_NEW_PASSWORD}`,
        })
      );
      toast.info("Processing please wait");
    } else {
      toast.error("Please input an email");
    }
  };

  return (
    <ResetPassword.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="title">
            <div className="primary-heading">
              Reset
              <span className="secondary-heading"> password</span>
            </div>

            <div className="sm-heading">
              Enter your email address to reset password
            </div>
          </div>
          <Input
            name="email"
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="link">
            <Link to={LOGIN} className="link">
              Back to Login
            </Link>
          </div>

          <div className="button">
            <Button
              height="4rem"
              disabled={forgotPassword.processing}
              onClick={handleReset}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </AuthCard>
    </ResetPassword.Wrapper>
  );
};

ResetPassword.Wrapper = styled.div`
  margin-top: 3rem;
  .title {
    margin: 2.5rem auto;
    text-align: center;
    .primary-heading {
      font-size: 2rem;
      font-weight: bold;
      color: #059157;
    }
    .secondary-heading {
      color: #47486b;
      font-style: italic;
    }
    img {
      height: 2.5rem;
    }
  }
  .sm-heading {
    margin: 1.5rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #080b4d;
    opacity: 0.6;
  }
  .link {
    color: #08cb7b;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-decoration: none;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export default ResetPassword;
