import { takeLatest, call } from "redux-saga/effects";
import api, { paymentRequest } from "services/api";
import * as types from "redux/types";
import { safeSaga } from "helpers";
import { successHandler } from 'helpers/apiRequests';


function* initailizeAddCard() {
  const response = yield call([api, "post"], paymentRequest.INITIALIZE);

  yield call(successHandler, response, types.INITIALIZE_SUCCESS)
}

function* getCards() {
  const response = yield call([api, "get"], paymentRequest.CARDS);

  yield call(successHandler, response, types.GET_CARDS_SUCCESS)
}

function* addCard({ payload }) {
  const response = yield call([api, "post"], paymentRequest.CARDS, payload);

  yield call(successHandler, response, types.ADD_CARD_SUCCESS, "Card Added Successfully")
}

function* getBankList() {
  const response = yield call([api, "get"], paymentRequest.BANKS);

  yield call(successHandler, response, types.GET_BANK_LIST_SUCCESS)
}

function* addBankDetails({ payload }) {
  const response = yield call([api, "post"], paymentRequest.BANK_DETAILS, payload);

  yield call(successHandler, response, types.ADD_BANK_DETAILS_SUCCESS, "Bank Added Successfully")
}

function* getUserSavedBanks() {
  const response = yield call([api, "get"], paymentRequest.BANK_DETAILS);

  yield call(successHandler, response, types.GET_ALL_USER_SAVED_BANKS_SUCCESS)
}

function* deleteUserCard({ payload }) {
  yield call([api, "delete"], paymentRequest.CARDS + '/' + payload.id);

  const response = {
    data: { id: payload.id },
    status: 200
  }

  yield call(successHandler, response, types.DELETE_CARD.success, "Card Removed successfully")
}

function* deleteUserBank({ payload }) {
  yield call([api, "delete"], paymentRequest.BANK_DETAILS + '/' + payload.id);

  const response = {
    data: { id: payload.id },
    status: 200
  }

  yield call(successHandler, response, types.DELETE_BANK.success, "Bank Removed successfully")
}

function* widthdrawCash({ payload }) {
  const response = yield call([api, "post"], paymentRequest.WITHDRAWAL, payload);

  yield call(successHandler, response, types.WITHDRAW_CASH.success, true)
}

export default function* taskSaga() {
  yield takeLatest(types.INITIALIZE_REQUEST, safeSaga(initailizeAddCard, types.INITIALIZE_ERROR));
  yield takeLatest(types.GET_CARDS_REQUEST, safeSaga(getCards, types.GET_CARDS_ERROR));
  yield takeLatest(types.ADD_CARD_REQUEST, safeSaga(addCard, types.ADD_CARD_ERROR));
  yield takeLatest(types.GET_BANK_LIST_REQUEST, safeSaga(getBankList, types.GET_BANK_LIST_ERROR));
  yield takeLatest(types.ADD_BANK_DETAILS_REQUEST, safeSaga(addBankDetails, types.ADD_BANK_DETAILS_ERROR));
  yield takeLatest(types.GET_ALL_USER_SAVED_BANKS_REQUEST, safeSaga(getUserSavedBanks, types.GET_ALL_USER_SAVED_BANKS_ERROR));
  yield takeLatest(types.DELETE_CARD.request, safeSaga(deleteUserCard, types.DELETE_CARD.error));
  yield takeLatest(types.DELETE_BANK.request, safeSaga(deleteUserBank, types.DELETE_BANK.error));
  yield takeLatest(types.WITHDRAW_CASH.request, safeSaga(widthdrawCash, types.WITHDRAW_CASH.error));
}
