import { combineReducers } from "redux";
import authReducer from "./auth";
import savingReducer from "./savings";
import paymentReducer from "./payment";
import transactionReducer from "./transaction";
import { LOGOUT_SUCCESS } from 'redux/types';

const appReducer = combineReducers({
  auth: authReducer,
  saving: savingReducer,
  payment: paymentReducer,
  transactions: transactionReducer,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;