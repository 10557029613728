import styled from "@emotion/styled";
import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const SkeletonCard = () => {
    return (
        <SkeletonCard.Wrapper>
          <SkeletonTheme>
             <div className="item">
                <h1 className="first"> <Skeleton  height={20} width={`50%`} /> </h1>
                <h1 className="icon end"> <Skeleton  height={50} width={`40%`} /> </h1>
             </div>
             <div className="item">
                <h1> <Skeleton  height={20} width={`50%`} /> </h1>
                <h1 className="end"> <Skeleton  height={20} width={`40%`} /> </h1>
             </div>
        </SkeletonTheme>
        </SkeletonCard.Wrapper>
    )
}

export default SkeletonCard


SkeletonCard.Wrapper = styled.div`
padding: 1rem 3rem;
width: 100%;
border-radius: 2rem;
margin-right: 2rem;
background-color: #f7f7f7;
 .item{
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     padding:  1rem;
    h1{
      width: 50%;
      margin-bottom: 2rem;
    }
    .first{

    }
    .end{
      text-align: end; 
    }
    .icon{
    span{
          border-radius: 50%;
      }
    }
  }
`
