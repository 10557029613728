import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "components/atoms/CustomButton";
import { Link, useHistory } from "react-router-dom";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import { LOGIN, PASSWORD_RESET_SUCCESS } from "../pagesPath";
import AuthHeading from "components/typographpy/AuthHeading";
import Input from "components/Input";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from 'redux/actions';
import { RootReducer } from 'redux/store';

const NewPassword = () => {
  const [passwords, setPasswords] = useState({
    password: '',
    password2: ''
  })
  const { password, password2 } = passwords;
  const dispatch = useDispatch();
  const resetPassword = useSelector((state: RootReducer) => state.auth.resetPassword);
  const history = useHistory();

  const [visible, setVisible1] = useState({
    visible1 : false,
    visible2 : false
  })

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPasswords({
      ...passwords,
      [name]: value
    })
  }


  useEffect(() => {
    if (resetPassword.success) {
      history.push(PASSWORD_RESET_SUCCESS)
    }
  }, [resetPassword.success, history]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{4,15}$/
   
    if (password !== password2){
      toast.dismiss()
      toast.error("Passwords don't match")
      return
    }
     else if(!password.match(validatePassword)){
        toast.dismiss()
        toast.error("Please include special characters,uppercase,lowercase,numbers");
        return
      }

        const url = new URL(window.location.href);
        const email = url.searchParams.get('email');
        const token = url.searchParams.get('token');

        dispatch(resetPasswordRequest({ email, password, token }));
        toast.dismiss()
        toast.info("Please wait processing...")
    
  }

  return (
    <NewPassword.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <div className="title">
            <AuthHeading
              primaryHeading={"Set new"}
              secondaryHeading={"password"}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="pass">
              <Input
                placeholder="New Password"
                name="password"
                type={visible.visible1 ? "text" : "password" }
                value={password}
                autoComplete="new-password"
                onChange={handleChange}
                required
              />     
             { visible.visible1 ?
              <div onClick={() => setVisible1({ ...visible, visible1 : false })}>
                  <i className="togglePassword fa fa-eye" id="togglePassword"></i>  
              </div> : 
               <div onClick={() => setVisible1({...visible,visible1 : true })}>
                  <i className="togglePassword fa fa-eye-slashed" id="togglePassword"></i>
               </div> }
               
                </div>
            <div className="pass">
            <Input
              placeholder="Re-enter Password"
              name="password2"
              type={visible.visible2 ? "text" : "password" }
              autoComplete="new-password"
              value={password2}
              onChange={handleChange}
              required
            />
             { visible.visible2 ?
              <div onClick={() => setVisible1({...visible,visible2 : false })}>
                  <i className="togglePassword fa fa-eye" id="togglePassword"></i>  
              </div> : 
               <div onClick={() => setVisible1({...visible, visible2 : true })}>
                  <i className="togglePassword fa fa-eye-slashed" id="togglePassword"></i>
               </div>}
                
            </div>
   
            <div className="link">
              <Link to={LOGIN} className="link">
                Back to Signin
            </Link>
            </div>

            <div className="button">
              <Button height="4rem"
                disabled={resetPassword.processing}
              >Submit</Button>
            </div>
          </form>
        </div>
      </AuthCard>
    </NewPassword.Wrapper>
  );
};

NewPassword.Wrapper = styled.div`
  .title {
    padding: 2rem 0;
  }
  .pass{
      position: relative;
    }
  .link {
    color: #08cb7b;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-decoration: none;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .fa-eye:before {
    content: "\f06e";
    position: absolute;
    right: 4%;
    top: 30%;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .fa-eye-slashed:before{
    content: "\f070";
    position: absolute;
    right: 4%;
    top: 30%;
    font-size: 1.4rem;
    cursor: pointer;
  }
`;
export default NewPassword;
