import { FormStage } from 'components/templates/NewSavingTemplate';
import { SAVINGS } from 'pages/pagesPath';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stage, StageMapperValue } from './SavingsTypes';

export type UseNewSavingsReturnType = [
  Stage,
  {
    handleContinue: () => void;
    handleBack: () => void;
  }
];


export function useNewSavingsPlan<T extends FormStage = FormStage>
  (formStagesMapper: Record<T, StageMapperValue>,
    onNextPageEmpty: () => void): UseNewSavingsReturnType {
  const history = useHistory();

  const [currentStage, setCurrentStage] = React.useState<Stage>("name");

  const handleContinue = () => {
    const nextPage = formStagesMapper[currentStage][1];

    if (!nextPage) {
      onNextPageEmpty();
    } else {
      setCurrentStage(nextPage);
    }
  };


  const handleBack = () => {
    const prevPage = formStagesMapper[currentStage][0];

    if (!prevPage) {
      history.push(SAVINGS);
    } else {
      setCurrentStage(prevPage);
    }
  };

  const handlers = {
    handleContinue,
    handleBack,
  }

  return [currentStage, handlers];
}
