/* eslint-disable no-console */
import styled from "@emotion/styled";
import Input from "components/Input";
import React, { useState, useEffect } from "react";
import closeIcon from "assets/images/close.svg";
import CustomButton from "components/atoms/CustomButton";
import { Heading } from "../typographpy/Heading";
import { useSelector } from "react-redux";
import { RootReducer } from "redux/store";

type InviteMembers = {
  members?: any;
  modal: boolean;
  regMember?: any;
  no_of_members?: number;
  closeModal: () => void;
  inviteHandler: (e) => void;
};

const InviteMembers = (props: InviteMembers) => {
  const { no_of_members, modal, closeModal, inviteHandler } = props;

  const userInfo = useSelector((state: RootReducer) => state.auth.user.data);
  const [inviteForm, setInviteForm] = useState([
    {
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      payout_order: 7,
    },
  ]);

  useEffect(() => {
    const generateFields = (no_of_members) => {
      const num = parseInt(no_of_members);

      const x: typeof inviteForm = [];

      for (let index = 1; index <= num; index++) {
        index === 1
          ? x.push({
              name: userInfo.name,
              email: userInfo.email,
              phone: userInfo.phone,
              payout_order: index,
            })
          : x.push({ name: "", email: "", phone: "", payout_order: index });
        console.log(index);
      }

      setInviteForm(x);
    };

    generateFields(no_of_members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (i, e) => {
    const newFormValues = [...inviteForm];

    newFormValues[i][e.target.name] = e.target.value;
    setInviteForm(newFormValues);
  };

  return (
    <InviteMembers.Wrapper>
      {modal ? (
        <img src={closeIcon} alt="close-icon" onClick={() => closeModal()} />
      ) : (
        ""
      )}

      <h1 className="members">
        Invite <span>{no_of_members ? no_of_members - 1 : 0}</span> members{" "}
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          inviteHandler(inviteForm);
          localStorage.setItem("members", JSON.stringify(inviteForm));
        }}
      >
        <div className="invite-form">
          <div className="invitesHeader">
            <div>
              <Heading type="h2">Name</Heading>
            </div>
            <div>
              <Heading type="h2">Email</Heading>
            </div>
            <div>
              <Heading type="h2">Phone number</Heading>
            </div>
            <div>
              <Heading type="h2">Collection Order</Heading>
            </div>
          </div>
          {inviteForm.map((element, index) =>
            index === 0 ? (
              <div key={index} className="inviteRow">
                <div>
                  <Input
                    type="text"
                    name="name"
                    required
                    value={inviteForm[0].name}
                    readOnly
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name={"email"}
                    required
                    value={inviteForm[0].email}
                    readOnly
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name={"phone"}
                    required
                    value={inviteForm[0].phone}
                    readOnly
                  />
                </div>
                <div>
                  <Input
                    name={"payout_order"}
                    type="text"
                    required
                    value={index + 1}
                    readOnly
                    cols={4}
                  />
                </div>
              </div>
            ) : (
              <div key={index} className="inviteRow">
                <div>
                  <Input
                    type="text"
                    name="name"
                    required
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name={"email"}
                    required
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name={"phone"}
                    required
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div>
                  <Input
                    name={"payout_order"}
                    type="text"
                    required
                    // onChange={(e) => handleChange(index, e)}
                    value={index + 1}
                    readOnly
                    cols={4}
                  />
                </div>
              </div>
            )
          )}
        </div>

        <div className="submit-button">
          {modal ? <CustomButton type="submit">Invite</CustomButton> : ""}
        </div>
      </form>
    </InviteMembers.Wrapper>
  );
};

InviteMembers.Wrapper = styled.div`
  min-width: 35%;
  padding: 4rem 3rem;
  background-color: white;
  border-radius: 20px;
  position: relative;
  height: auto;
  img {
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 3%;
  }
  .invitesHeader {
    display: flex;
    flex-direction: column nowrap;
    justify-content: space-between;
    div {
      text-align: left;
    }
  }
  .inviteRow {
    display: flex;
    flex-direction: column nowrap;
    width: 100%;
    border-bottom: 3px solid #a8fcd9;
  }
  form {
    width: 100%;
  }
  .members {
    color: #292d61;
    font-weight: bold;
    margin: 1rem 0;
    span {
      background-color: #f6fefd;
      color: #476ac9;
      padding: 1rem;
      border-radius: 1rem;
    }
  }
  .invite {
    color: #726b99;
    margin: 1rem 0;
    font-size: 1.3rem;
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
  .tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 4.8rem;
    width: 480px;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    &:focus-within {
      border: 1px solid #0052cc;
    }
    input {
      flex: 1;
      border: none;
      height: 46px;
      font-size: 14px;
      padding: 4px 0 0 0;
      width: 100%;
      &:focus {
        outline: transparent;
      }
      &:disabled {
        background-color: white;
      }
    }
    input[type="text"]:disabled {
      backgroud-color: white;
      opacity: 0;
    }
  }

  #tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
  }

  .tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #47486b;
    padding: 0 3px;
    font-size: 12px;
    list-style: none;
    border-radius: 6px;
    margin: 0 5px 5px 0;
    background: #e6ffea;
    .tag-title {
      margin-top: 3px;
    }
    .tag-close-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      margin-left: 8px;
      color: green;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 567px) {
    .tags-input {
      width: calc(100vw - 32px);
    }
  }
`;

export default InviteMembers;
