import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Button from "components/atoms/CustomButton";
import clappingHand from "assets/icons/176-waving-hand.svg";
import Input from "components/Input";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signRequest } from "redux/actions";
import Spinner from "components/Spinner";
import { HOME, RESEND_VERIFICATION, SIGNUP } from "pages/pagesPath";
import AuthBackground from "components/AuthBackground";
import AuthBrand from "components/AuthBrand";
import AuthCard from "components/cards/AuthCard";
import AuthHeading from "components/typographpy/AuthHeading";


const Login = () => {
  const { success, processing } = useSelector(
    (state: any) => state.auth.user
  );

  const history = useHistory();

  useEffect(() => {
    if (success && localStorage.getItem("token")) history.push(HOME);

  }, [success, history]);

  const dispatch = useDispatch();
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;

  const signInUser = (e: any) => {
    e.preventDefault();
    dispatch(signRequest(formData));
  };

  const handleChange = (e: any) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Login.Wrapper>
      <AuthBackground />
      <AuthCard>
        <AuthBrand />
        <div className="main">
          <AuthHeading
            primaryHeading={"Welcome "}
            secondaryHeading={"back"}
            icon={clappingHand}
          />
          <div className="sm-heading">Sign in to your account</div>

          <form onSubmit={signInUser}>
            <Input
              placeholder="Email Address"
              name="email"
              type="email"
              required={true}
              value={email}
              onChange={handleChange}
            />
            <div className="pb">
              <Input
                placeholder="Password"
                name="password"
                type="password"
                required
                value={password}
                onChange={handleChange}
                autoComplete="false"
              />
            </div>
            <div className="links">
              <Link to="/reset-password" className="forgot-password" >
                Forgot Password?
            </Link>
              <Link to={RESEND_VERIFICATION} className="forgot-password">
                Resend Email
          </Link>
            </div>
            <div className="submission">
              <div className="captcha"></div>
              <Button>{processing ? <Spinner /> : "Sign In"}</Button>
            </div>
          </form>
        </div>
      </AuthCard>
      <div className="footer">
        Don&apos;t have an account yet?
        <Link to={SIGNUP} className="footer-primary">
          Sign Up
        </Link>
      </div>
    </Login.Wrapper>
  );
};

Login.Wrapper = styled.div`
  margin: 8rem auto;
  .pb {
    padding-bottom: 1rem;
  }
  .sm-heading {
    text-align: center;
    margin: 1.5rem 0;
    font-size: 1.2rem;
    color: #080b4d;
    opacity: 0.6;
  }
  .links{
    display:flex;
    justify-content:space-between;
    /* align-items:center; */
    margin-top:-2rem
  }
  .forgot-password {
    color: #08cb7b;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 2rem 0;
    text-decoration: none;
  }
  .submission {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    .captcha {
      width: 3rem;
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    margin: 2rem auto;
    font-size: 1.2rem;
    .footer-primary {
      font-weight: bold;
      color: #08cb7b;
      text-decoration: none;
      margin-left: 1.5rem;
    }
  }
`;
export default Login;
