import { RouterProps } from "react-router";
import { convertRoutesToComponents } from "helpers";
import { publicRoutes } from "./routes";
import { HOME } from "../pagesPath";


const UnAuthPages = (props: RouterProps) => {
  const { history } = props;

  if (localStorage.getItem("token")) {
    history.push(HOME);
    // return convertRoutesToComponents(protectedRoutes)
  }

  return convertRoutesToComponents(publicRoutes);
};

export default UnAuthPages;
