import {
  defaultSingleObjectState,
  defaultManyObjectState,
  defaultSingleObjectArrayState
} from "utils/constants";
import { extractStatus, handleFetch } from "helpers";
import * as types from "redux/types";


const defaultPayload = {
  data: [],
  errors: [],
};
const initalState = {
  paystack: defaultSingleObjectState,
  cards: defaultManyObjectState,
  newCard: defaultSingleObjectState,
  bankList: defaultSingleObjectState,
  bankDetails: defaultSingleObjectState,
  userSavedBanks: defaultSingleObjectArrayState,
  deleteCard: {
    loading: false
  },
};

const paymentReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);

  switch (type) {
    case types.INITIALIZE_REQUEST:
    case types.INITIALIZE_SUCCESS:
    case types.INITIALIZE_ERROR:
      return handleFetch(state, status, payload, "paystack");
    case types.GET_CARDS_REQUEST:
    case types.GET_CARDS_SUCCESS:
    case types.GET_CARDS_ERROR:
      return handleFetch(state, status, payload, "cards");
    case types.ADD_CARD_REQUEST:
    case types.ADD_CARD_ERROR:
      return handleFetch(state, status, payload, "newCard");
    case types.GET_BANK_LIST_REQUEST:
    case types.GET_BANK_LIST_SUCCESS:
    case types.GET_BANK_LIST_ERROR:
      return handleFetch(state, status, payload, "bankList");
    case types.ADD_BANK_DETAILS_REQUEST:
    case types.ADD_BANK_DETAILS_ERROR:
      return handleFetch(state, status, payload, "bankDetails");
    case types.GET_ALL_USER_SAVED_BANKS_REQUEST:
    case types.GET_ALL_USER_SAVED_BANKS_SUCCESS:
    case types.GET_ALL_USER_SAVED_BANKS_ERROR:
      return handleFetch(state, status, payload, "userSavedBanks");
    case types.ADD_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        userSavedBanks: {
          ...state.userSavedBanks,
          data: [...state.userSavedBanks.data, payload.data]
        },
      }

    case types.DELETE_BANK.success:
      return {
        ...state,
        userSavedBanks: {
          ...state.userSavedBanks,
          data: state.userSavedBanks.data.filter(
            (bank) => bank.id !== payload.id
          )
        },
        deleteCard: {
          ...state.deleteCard,
          loading: false
        }
      }
    case types.DELETE_CARD.request:
      return {
        ...state,
        deleteCard: {
          ...state.deleteCard,
          loading: true
        }
      }
    case types.DELETE_CARD.success:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: state.cards.data.filter(
            (card) => card.id !== payload.id
          )
        },
        deleteCard: {
          ...state.deleteCard,
          loading: false
        }
      }
    case types.ADD_CARD_SUCCESS:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: [...state.cards.data, payload.data]
        },
      }
    default:
      return state;
  }
};

export default paymentReducer;
