import * as types from "redux/types";

export const initializeRequest = () => ({
  type: types.INITIALIZE_REQUEST,
});

export const getCardsRequest = () => ({
  type: types.GET_CARDS_REQUEST,
});
export const addCardRequest = (payload) => ({
  type: types.ADD_CARD_REQUEST,
  payload,
});

export const getBankList = () => ({
  type: types.GET_BANK_LIST_REQUEST,
})

export const addUserBankDetails = (payload) => ({
  type: types.ADD_BANK_DETAILS_REQUEST,
  payload
})

export const getUserSavedBanksRequest = () => ({
  type: types.GET_ALL_USER_SAVED_BANKS_REQUEST
})
export const deleteCard = (payload) => ({
  type: types.DELETE_CARD.request,
  payload
})

export const deleteBank = (payload) => ({
  type: types.DELETE_BANK.request,
  payload
})
export const widthdrawCash = (payload) => ({
  type: types.WITHDRAW_CASH.request,
  payload
})
