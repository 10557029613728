import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NOT_FOUND } from "pages/pagesPath";

interface RouteType {
  path: string;
  component: React.FC<any>;
  exact?: boolean;
}

export const convertRoutesToComponents = (routes: RouteType[]) => {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <Redirect to={NOT_FOUND} />
    </Switch>
  );
};

export function convertParamsToString(url: string, urlVariables: Record<string, string>): string {
  let finalURL = url;

  for (const [key, value] of Object.entries(urlVariables)) {
    finalURL = finalURL.replace(`:${key}`, value);
  }

  return finalURL
}