import styled from "@emotion/styled";
import { css } from "@emotion/core";

export const Tab = styled.div`
  display: flex;
  @media screen and (max-width: 960px) {
     justify-content: space-between;
     align-items: center;
      }

`;

type TabItemProps = {
  active?: boolean;
};

export const TabItem = styled.div<TabItemProps>`
  padding: 0.8rem 34px;
  font: normal normal 800 1.5rem/1.9rem Muli;
  color: #ccc;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      background: #e8eaff 0% 0% no-repeat padding-box;
      border-radius: 34px;
      font-weight: bold;
      font-family: Graphie;
      margin-right: 1.5rem;
      color: #292d61;
    `}
    @media screen and (max-width: 960px) {
        padding: 1rem;
        height: fit-content;
      }

`;
