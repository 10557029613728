import React from "react";
interface WithSuspenseProps {
  page: string;
  data?: Record<string, any>;
}

function withSuspense(args: WithSuspenseProps): React.FC<any> {
  const { page, data } = args;

  return function InnerComponent(
    props: Record<string, any>
  ): React.ReactElement<Record<string, any>> {
    const LazyComponent = React.lazy(() => import(`pages/${page}`));

    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyComponent {...props} {...data} />
      </React.Suspense>
    );
  };
}

export default withSuspense;
