import React from "react";
import styled from "@emotion/styled";

type BackButtonProps = {
  onClick?: () => void;
  hideLt?: boolean;
  color?: string;
};

function BackButton(props: BackButtonProps) {
  const { hideLt, color = "#059157", onClick } = props;

  return (
    <BackButton.Wrapper color={color} onClick={onClick}>
      {!hideLt && <span className="lt">&lt;</span>}
      <span>Back</span>
    </BackButton.Wrapper>
  );
}

BackButton.Wrapper = styled.div<{ color: string }>`
  .lt {
    margin-right: 5px;
  }
  color: ${(props) => props.color};
  font: normal normal 600 1.6rem/2.4rem Muli-ExtraBold;
  cursor: pointer;
  text-align: left;
  font: normal normal 600 16px/20px Muli;
  letter-spacing: 0px;
  color: #059157;
`;

export default BackButton;
