import React, { useEffect, useState } from "react";
import {
  IndividualSavingsFormStage, SavingDetails,
} from "components/templates/NewSavingTemplate";
import { useDispatch, useSelector } from "react-redux";
import { createIndividualSaving } from "redux/actions";
import { toast } from "react-toastify";
import { StageMapperValue } from './SavingsTypes';
import { NewSavingsContainer } from './NewSavingsContainer';
import { RootReducer } from 'redux/store';


function NewIndividualSavings() {
  const dispatch = useDispatch();

  // const formStagesMapper: Record<IndividualSavingsFormStage, StageMapperValue> = {
  //   name: [null, "savingsTarget"],
  //   savingsTarget: ["name", "cycle"],
  //   cycle: ["savingsTarget", "confirmModal"],
  //   confirmModal: ["cycle", "confirm"],
  //   confirm: ["cycle", null],
  // };

  const formStagesMapper: Record<IndividualSavingsFormStage, StageMapperValue> = {
    name: [null, "confirmModal"],
    confirmModal: ["name", "confirm"],
    confirm: ["confirmModal", null],
  };



  const createSavingState = useSelector(
    (state: RootReducer) => state.saving.create
  );
  const [createSuccessfully, setcreateSuccessfully] = useState(false);

  useEffect(() => {
    if (createSavingState.success) {
      setcreateSuccessfully(true);
      toast.success("Plan has been created");
    }
  }, [createSavingState.success]);

  //handles errors creating the saving plan
  // useEffect(() => {
  //   if (Object.keys(createSavingState.errors).length !== 0 &&
  //     createSavingState.errors.constructor !== Object) {
  //     toast.error(createSavingState.errors);
  //   }
  // }, [createSavingState.errors]);

  const sendToAPI = (values) => {
    // prepare values for backend
    const sendToDB = {
      name: values.name,
      amount: values.routineAmount,
      target_amount: values.target,
      plan: values.frequency,
      ...(values.dayOfMonth && { day_of_month: values.dayOfMonth }),
      ...(values.dayOfWeek && { day_of_week: values.dayOfWeek }),
      hour_of_day: values.hourOfDay === -1 ? 1 : values.hourOfDay,
      payment_auth: values.payment,
      start_date: values.startDate?.format("YYYY-MM-DD"),
      end_date: values.endDate?.format("YYYY-MM-DD"),
      description: "This is description",
    };

    dispatch(createIndividualSaving(sendToDB));
    toast.info("Processing please wait...");

  }

  const newIndividualSaving: SavingDetails = {
    savingType: 'Individual',
    description: "Give your individual saving plan a name",
    savingPlaceholder: "Laptop Purchase",
    target: 'Set a saving target',
    frequency: 'How often do you want to save?'
  }
  
  return (
    <NewSavingsContainer
      savingDetails={newIndividualSaving}
      formStagesMapper={formStagesMapper}
      onNextPageIsEmpty={sendToAPI}
      isCreateSuccessful={createSuccessfully}
      Id={createSavingState.data.id}
    />
  )
}


export default NewIndividualSavings;
