import { takeLatest, call } from "redux-saga/effects";
import api, { transactionRequest } from "services/api";
import * as types from "redux/types";
import { safeSaga } from "helpers";
import { successHandler } from 'helpers/apiRequests';
import { convertParamsToString } from 'helpers/pagesParser';

function* getTransaction({ payload }) {
  const url = convertParamsToString(transactionRequest.TRANSACTIONS_SORT, payload.filterType);  
  
  const response = yield call([api, "get"], url+'&filter[status]=success');

  switch (payload.tabName) {
    case "Daily":
      yield call(successHandler, response, types.GET_DAILY_TRANSACTIONS.success)
      break;
    case "Weekly":
      yield call(successHandler, response, types.GET_WEEKLY_TRANSACTIONS.success)
      break;
    case "Monthly":
      yield call(successHandler, response, types.GET_MONTHLY_TRANSACTIONS.success)
      break;
    default:
      yield call(successHandler, response, types.GET_ALL_TRANSACTIONS.success)
      break;
  }

}

export default function* transactionSaga() {
  yield takeLatest(types.GET_ALL_TRANSACTIONS.request, safeSaga(getTransaction, types.GET_ALL_TRANSACTIONS.error));
  yield takeLatest(types.GET_DAILY_TRANSACTIONS.request, safeSaga(getTransaction, types.GET_DAILY_TRANSACTIONS.error));
  yield takeLatest(types.GET_WEEKLY_TRANSACTIONS.request, safeSaga(getTransaction, types.GET_WEEKLY_TRANSACTIONS.error));
  yield takeLatest(types.GET_MONTHLY_TRANSACTIONS.request, safeSaga(getTransaction, types.GET_MONTHLY_TRANSACTIONS.error));
}
