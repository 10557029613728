import styled from "@emotion/styled";
import React from "react"
import Skeleton from 'react-loading-skeleton';

const LoadingSkeletonBody = () => {
    return (
    <LoadingSkeletonBody.Wrapper>
        <div className="sidebar">
          <h2 className="sidebar-title">
            <Skeleton  height={20} width={`50%`} />
          </h2>
           <div className="image">
           <Skeleton  height={`100%`} width={`100%`} />
           </div>
           <div className="name">
           <Skeleton  height={20} width={`80%`} />  
           </div>
            <div className="links">
            <Skeleton  height={20} width={`80%`} />  
            <Skeleton  height={20} width={`80%`} />  
            <Skeleton  height={20} width={`80%`} />  
            <Skeleton  height={20} width={`80%`} />  
            </div>
        </div>
       
  
        <div className="list">
          {Array(5)
            .fill(5)
            .map((item, index) => (
              <div className="card" key={index}>
                <div className="card-image">
                  <Skeleton height={20} width={`50%`} />
                </div>
                <h4 className="card-title">
                  <Skeleton height={30} width={`90%`} />
                </h4> 
                    <p>
                      <Skeleton height={20} width={`70%`} />
                    </p>
                    <span>
                      <Skeleton height={20} width={`70%`} />
                    </span>
              </div>
            ))}
        </div>

        <div className="last">
          <div>    <Skeleton  height={100} width={`90%`} />  </div>  
           <div>   <Skeleton  height={30} width={`75%`} />  </div>
           <div>   <Skeleton  height={30} width={`75%`} />  </div>
          <div>    <Skeleton  height={30} width={`50%`} /> </div> 
        </div>
        </LoadingSkeletonBody.Wrapper>
    );
  };

export default LoadingSkeletonBody;

LoadingSkeletonBody.Wrapper = styled.div`
display: flex;
.sidebar{
 padding-top: 4rem;
 width: 20%;
 padding-left: 2rem;
 text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
 .sidebar-title, .name{
     width: 100%;
     margin-top: 2rem;
 }
 .image{
    width: 40%;
    height: 8rem;
    border-radius: 50%;
    margin: 3rem auto;  
   span {
       border-radius: 20%;
   }
 }
 .links{
     margin-top: 6rem;
    width: 100%;
    span > * {
        margin: 2rem auto;  
    }
 }
}
.list{
    position: relative;
    width: 55%;
    padding-top: 4rem;
    .card{
      margin-bottom: 3rem;
      & > * {
        margin: 1rem auto;
      }
    }
}
.last{
  margin-top: 4rem;
  width: 20%;
  height: 33rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items:center;
  text-align: center;
  border-radius: 1rem;
   & > div {
  width: 100%;
  margin: 1rem auto;
  }
}
`