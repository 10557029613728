export const NOT_FOUND = "/404";
export const SERVER_ERROR = "/500";
export const UNAUTHORIZED = "/401";

export const USER = "/user";
export const LOGIN = "/login";
export const TERMS = "/terms";
export const SIGNUP = "/signup";
export const EMAIL_SENT = "/email-sent";
export const RESET_PASSWORD = "/reset-password";
export const RESEND_VERIFICATION = "/resend-verification";
export const ENTER_NEW_PASSWORD = "/enter-new-password";
export const PASSWORD_RESET_SUCCESS = "/password-reset-success";
export const GROUP = "/group";
export const INDIVIDUAL = "/individual";
export const TARGET = "/target";
export const VERIFY_USER_TOKEN = "/verify-token";

// auth paths
export const HOME = "/user/home";
export const TRANSACTION = "/user/transaction";
export const ACCOUNT = "/user/account";

// Savings
export const SAVINGS = "/user/savings";
export const INDIVIDUAL_SAVINGS = `${SAVINGS}/individual`;
export const VIEW_INDIVIDUAL_SAVINGS = `${SAVINGS}/view/:id`;
export const WITHDRAWAL = `/user/withdrawal`;
export const PART_VIEW_INDIVIDUAL_SAVINGS = `${SAVINGS}/view`;
export const GROUP_SAVINGS = `${SAVINGS}/group`;
export const GROUP_TARGET = `${SAVINGS}/target`;
export const NEW_SAVINGS = `${SAVINGS}/new`;
export const NEW_INDIVIDUAL_SAVINGS = `${NEW_SAVINGS}/individual`;
export const NEW_GROUP_SAVINGS = `${NEW_SAVINGS}/group`;
export const NEW_TARGET_SAVINGS = `${NEW_SAVINGS}/target`;

export const GROUP_INVITES = `/user/group/user/invites`;
export const TARGET_INVITES = `/user/target/user/invites`;
export const ACCEPTED_GROUP_INVITE = `${SAVINGS}/group/accepted-invites`;
export const ACCEPTED_TARGET_INVITE = `${SAVINGS}/group/accepted-target`;
// export const SINGLE_ACCEPTED_GROUP_INVITE = `${SAVINGS}/accepted-invites/:id`
export const TRANSACTION_DOCUMENT = `/user/transactions-document`;
