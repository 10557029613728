import { defaultSingleObjectState } from "utils/constants";
import { extractStatus, handleFetch } from "helpers/reducerHelper";
import * as types from "redux/types";
import { RESET_PASSWORD, UPDATE_PASSWORD } from 'redux/types';

const defaultPayload = {
  data: [],
  errors: [],
};

const initalState = {
  user: defaultSingleObjectState,
  profileImg: defaultSingleObjectState,
  profileUpdate: defaultSingleObjectState,
  forgotPassword: defaultSingleObjectState,
  resetPassword: defaultSingleObjectState,
  updatePassword : defaultSingleObjectState
}

const authReducer = (
  state = initalState,
  { type, payload = defaultPayload }: any
) => {
  const status = extractStatus(type);

  switch (type) {
    case types.SIGN_IN_REQUEST:
    case types.SIGN_IN_SUCCESS:
    case types.SIGN_IN_ERROR:
    case types.SIGN_UP_REQUEST:
    case types.SIGN_UP_SUCCESS:
    case types.SIGN_UP_ERROR:
    case types.GET_USER_DETAILS_REQUEST:
    case types.GET_USER_DETAILS_SUCCESS:
    case types.GET_USER_DETAILS_ERROR:
    case types.VERIFY_USER_TOKEN_REQUEST:  
    case types.VERIFY_USER_TOKEN_SUCCESS:
    case types.VERIFY_USER_TOKEN_ERROR:  
      return handleFetch(state, status, payload, "user");
    case types.UPDATE_PROFILE_REQUEST:
    case types.UPDATE_PROFILE_SUCCESS:
    case types.UPDATE_PROFILE_ERROR:
      return handleFetch(state, status, payload, "profileUpdate");
    case types.FORGOT_PASSWORD_REQUEST:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.FORGOT_PASSWORD_ERROR:
      return handleFetch(state, status, payload, "forgotPassword");
    case RESET_PASSWORD.request:
    case RESET_PASSWORD.success:
    case RESET_PASSWORD.error:
      return handleFetch(state, status, payload, "resetPassword");
      case UPDATE_PASSWORD.request:
      case UPDATE_PASSWORD.success: 
      case UPDATE_PASSWORD.error: 
      return handleFetch(state, status, payload, "updatePassword");
    default:
      return state;
  }
};

export default authReducer;
