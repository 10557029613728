import React from "react";
import styled from "@emotion/styled";
import logo from 'assets/images/logo2.png'
import { useHistory } from "react-router-dom";

const AuthBrand = () => {
  const history = useHistory();

  return (
    <AuthBrand.Wrapper>
        <img 
        src={logo} alt="logo" 
        className="logo" 
        onClick={() => { history.push("/")}}    
        />
    </AuthBrand.Wrapper>
  );
};

AuthBrand.Wrapper = styled.div`
  color: #7a7b94;
  font-size: 1.6rem;
  font-weight: 700;
  .logo {
    width: 6rem;
    cursor: pointer;
  }
`;

export default AuthBrand;
