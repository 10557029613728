import { takeLatest, call, put } from "redux-saga/effects";
import api, { authRequest } from "services/api";
import * as types from "redux/types";
import { safeSaga } from "helpers";
import { successHandler } from 'helpers/apiRequests';
import { RESET_PASSWORD } from 'redux/types';

function* signInUser({ payload }: Record<string, any>) {
  const { data } = yield call([api, "post"], authRequest.LOGIN, payload);

  yield put({
    type: types.SIGN_IN_SUCCESS,
    payload: data,
  });
  localStorage.token = data.access_token;
}

function* forgotPassword({ payload }: Record<string, any>) {
  const response = yield call([api, "post"], authRequest.FOGOT_PASSWORD, payload);

  yield call(successHandler, response, types.FORGOT_PASSWORD_SUCCESS, true)

}

function* verifyUserToken({ payload }: Record<string, any>) {
  const response = yield call([api, "post"], authRequest.VERIFY_USER_TOKEN, payload);

  yield call(successHandler, response, types.VERIFY_USER_TOKEN_SUCCESS, true)

}

function* resendVerification({ payload }: Record<string, any>) {
  const response = yield call([api, "post"], authRequest.RESEND_TOKEN, payload);

  yield call(successHandler, response, types.RESEND_VERIFICATION.success, true)

}

function* resetPassword({ payload }: Record<string, any>) {
  const response = yield call([api, "put"], authRequest.RESET_PASSWORD, payload);

  yield call(successHandler, response, RESET_PASSWORD.success, true)

}

function* signUpUser({ payload }: Record<string, any>) {
  const response = yield call([api, "post"], authRequest.REGISTER, payload);

  yield call(successHandler, response, types.SIGN_UP_SUCCESS, true)

}

function* updatePassword({ payload }: Record<string, any>) {
  const response = yield call([api, "put"], authRequest.UPDATE_PASSWORD, payload);

  yield call(successHandler, response, types.UPDATE_PASSWORD.success, true)

}


function* updateProfile({ payload }: Record<string, any>) {

  if (payload.formData.has('image')) {
    const { data } = yield call([api, "post"], authRequest.UPLOAD_IMAGE, payload.formData);

    yield call([api, "post"], authRequest.USER, { ...payload.userDetails, avatar: data.data.url });
  } else {
    yield call([api, "post"], authRequest.USER, payload.userDetails);
  }

  yield put({
    type: types.UPDATE_PROFILE_SUCCESS
  });
  yield put({
    type: types.GET_USER_DETAILS_REQUEST
  });
}

function* getUserDetails() {
  try {
    const { data } = yield call([api, "get"], authRequest.USER);

    yield put({
      type: types.GET_USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_USER_DETAILS_ERROR,
    });
    localStorage.clear();
  }
}

function* logout() {
  try {
    localStorage.clear();
    yield put({
      type: types.LOGOUT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.LOGOUT_ERROR,
    });
  }
}

export default function* authSaga() {
  yield takeLatest(types.SIGN_IN_REQUEST, safeSaga(signInUser, types.SIGN_IN_ERROR));
  yield takeLatest(types.SIGN_UP_REQUEST, safeSaga(signUpUser, types.SIGN_UP_ERROR));
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, safeSaga(updateProfile, types.UPDATE_PROFILE_ERROR));
  yield takeLatest(types.FORGOT_PASSWORD_REQUEST, safeSaga(forgotPassword, types.FORGOT_PASSWORD_SUCCESS));
  yield takeLatest(types.RESET_PASSWORD_REQUEST, safeSaga(resetPassword, types.RESET_PASSWORD_ERROR));
  yield takeLatest(types.VERIFY_USER_TOKEN_REQUEST, safeSaga(verifyUserToken, types.VERIFY_USER_TOKEN_ERROR));
  yield takeLatest(RESET_PASSWORD.request, safeSaga(resetPassword, RESET_PASSWORD.error));
  yield takeLatest(types.RESEND_VERIFICATION.request, safeSaga(resendVerification, types.RESEND_VERIFICATION.error));
  yield takeLatest(types.GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(types.LOGOUT_REQUEST, logout);
  yield takeLatest(types.UPDATE_PASSWORD.request, safeSaga(updatePassword, types.UPDATE_PASSWORD.error));
}
