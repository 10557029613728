import styled from "@emotion/styled";
import React from "react";

const FlexBox = ({ children, ...others }: any) => {
  return <FlexBox.Wrapper {...others}>{children}</FlexBox.Wrapper>;
};

FlexBox.Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default FlexBox;
