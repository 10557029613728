import { createActionType } from 'helpers/reducerHelper';

export const INITIALIZE_REQUEST = "INITIALIZE_REQUEST";
export const INITIALIZE_SUCCESS = "INITIALIZE_SUCCESS";
export const INITIALIZE_ERROR = "INITIALIZE_ERROR";

export const GET_CARDS_REQUEST = "GET_CARDS_REQUEST";
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_ERROR = "GET_CARDS_ERROR";

export const ADD_CARD_REQUEST = "ADD_CARD_REQUEST";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_ERROR = "ADD_CARD_ERROR";

export const GET_BANK_LIST_REQUEST = "GET_BANK_LIST_REQUEST";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_ERROR = "GET_BANK_LIST_ERROR";

export const ADD_BANK_DETAILS_REQUEST = " ADD_BANK_DETAILS_REQUEST";
export const ADD_BANK_DETAILS_SUCCESS = " ADD_BANK_DETAILS_SUCCESS";
export const ADD_BANK_DETAILS_ERROR = " ADD_BANK_DETAILS_ERROR";

export const GET_ALL_USER_SAVED_BANKS_REQUEST = "GET_ALL_USER_SAVED_BANKS_REQUEST";
export const GET_ALL_USER_SAVED_BANKS_SUCCESS = "GET_ALL_USER_SAVED_BANKS_SUCCESS";
export const GET_ALL_USER_SAVED_BANKS_ERROR = "GET_ALL_USER_SAVED_BANKS_ERROR";

export const DELETE_CARD = createActionType("DELETE_CARD");

export const DELETE_BANK = createActionType("DELETE_BANK");
export const WITHDRAW_CASH = createActionType("WITHDRAW_CASH");
